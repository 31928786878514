import React from "react";
import Listofcontacttable from "../tables/listofcontacttable";
import SelectFileModal from "../../modals/selectFileModal";
import { Tooltip } from "antd";

const Listofcontacts = () => {
  const [isfilemodalOpen, setisfilemodalOpen] = React.useState(false);
  
  const showEmailModal = () => {
    setisfilemodalOpen(true);
  };
  
  return (
    <>
      <div className="contacts-main-conatiner d-flex flex-column ">
        <div className="upload-contacts-div v-center h-center gap-5">
          <h4 className="elarge">Upload new file</h4>

          <div className="uplaod-conatiner v-center">
            <img src="../../assets/icons/upload.png" alt="" />

            <div className="upload-right v-center space-between gap-1">
              <p className="segoe-family text-black">
                Select a file or drag and drop here
              </p>

              <Tooltip title="Upload CSV file">
                <button
                  className="button-purple-transparent px-4 py-3 text-uppercase"
                  onClick={showEmailModal}
                >
                  Select file
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="spread-main h-center">
          <div className="spreadsheet-div d-flex flex-column">
            <h4 className="elarge">Spreadsheets</h4>
            <Listofcontacttable isfilemodalOpen={isfilemodalOpen} />
          </div>
        </div>
      </div>
      
      <SelectFileModal
        isfilemodalOpen={isfilemodalOpen}
        setisfilemodalOpen={setisfilemodalOpen}
      />
    </>
  );
};

export default Listofcontacts;
