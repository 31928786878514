import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../components/sidebar";
import Dashnavbar from "../components/dashnavbar";
import { Outlet } from "react-router-dom";
import { useCompaign } from "../context/CompaignProvider";
import { usePlans } from "../context/PlansProvider";
import planInfo from "../utils/planInfo";
import { useNotifications } from "../context/NotificationProvider";
import FreeTrailModal from "../modals/FreeTrailModal";
import getProfile from "../utils/getProfile";
import getNotifications from '../utils/notifications/getNotifications';

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setEditorContent, setColError, togglePopup, setTogglePopup, setProfile, profile, handleTemplates, setPreviewInstance, sid, setSid } = useCompaign();
  const { activePlan, setActivePlan } = usePlans();
  const { addNotification, getNotificationsList } = useNotifications();
  const socketid = sessionStorage.getItem("sid");
  const userId = sessionStorage.getItem("email");
  const wsNotificationRef = useRef(null);
  const wsPreviewRef = useRef(null);

  const handleNotification = (ws) => {
    ws.onopen = () => {
      console.log("Notifications Socket Connected!");
    };

    ws.onmessage = (event) => {
      if (event.data !== "ping") {
        try {
          let rawData = event.data;
          rawData = rawData.replace(/'/g, '"');
          rawData = rawData.replace(/None/g, 'null');
          rawData = rawData.replace(/True/g, 'true');
          rawData = rawData.replace(/False/g, 'false');

          const response = JSON.parse(rawData);
          addNotification({
            ...response
          });
        } catch (e) {
          console.log("Error parsing JSON:", e);
        }
      }
    };

    ws.onerror = (error) => {
      console.error("Notifications error:", error);
    };

    ws.onclose = (event) => {
      console.log("Notifications socket terminated!", event.reason);
    };
  };

  const handleGenPreviews = (ws) => {
    ws.onopen = () => {
      console.log(`Preview Socket Connected! ${sessionStorage.getItem("sid")}`);
    };

    ws.onmessage = (event) => {
      if (event.data !== "ping") {
        const data = JSON.parse(event.data);
        if (data?.error) {
          setColError(data.error);
          return;
        }
        try {
          setEditorContent((prev) => [...prev, data]);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = (event) => {
      console.log(`Socket ID is Closed! ${sessionStorage.getItem('sid')}`);
    };
  }

  const handleNotificationsList = async () => {
    const res = await getNotifications();
    if (res.success) {
      getNotificationsList(res.notifications);
    }
  }

  const fetchProfile = async () => {
    const response = await getProfile();
    if (response.success) {
      setProfile(response.data);
    }
  };

  const fetchActivePlan = async () => {
    const response = await planInfo();
    if (response.success) {
      setActivePlan(response.data);
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    handleTemplates();
    handleNotificationsList();
    fetchActivePlan();
    fetchProfile();
    setSid(socketid);

    if (!wsNotificationRef.current) {
      const SOCKET_URL_NOTIFICATION = `${process.env.REACT_APP_SOCKET_URL}/${userId}`;
      const wsNotification = new WebSocket(SOCKET_URL_NOTIFICATION);
      wsNotificationRef.current = wsNotification;
      handleNotification(wsNotification);

      return () => {
        if (wsNotificationRef.current) {
          wsNotificationRef.current.close();
          wsNotificationRef.current = null;
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!activePlan.subscription_name && profile.firstname && !profile.trial_used) {
      setTogglePopup(true);
    }
  }, [activePlan, profile]);

  useEffect(() => {
    if (!sid) {
      console.log("Socket ID required!");
      return;
    }

    if (!wsPreviewRef.current) { // Only create if not already created
      const SOCKET_URL_PREVIEW = `${process.env.REACT_APP_SOCKET_URL}/${sid}`;
      const wsPreview = new WebSocket(SOCKET_URL_PREVIEW);
      wsPreviewRef.current = wsPreview;
      setPreviewInstance(wsPreview);
      handleGenPreviews(wsPreview);

      return () => {
        if (wsPreviewRef.current) {
          wsPreviewRef.current.close();
          wsPreviewRef.current = null;
          console.log(`Socket ID is Closed! ${sessionStorage.getItem('sid')}`);
        }
      };
    }
  }, [sid]);

  return (
    <>
      <div className="Dashboard-main-container d-flex overflow-x-hidden">
        <div className="left-content">
          <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </div>

        <div className="right_content md:p-0 w-full md:w-[87%] ">
          <Dashnavbar toggleDrawer={toggleDrawer} />
          <div className="page-dom">
            <Outlet />
          </div>
        </div>

        <FreeTrailModal togglePopup={togglePopup} setTogglePopup={setTogglePopup} />
      </div>
    </>
  );
};

export default Dashboard;
