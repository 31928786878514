import React, { useEffect, useState } from "react";
import Settingssubscription from "./settingssubscription";
import ProfileComponent from "../../components/profilecomponent";
import { Skeleton } from "antd";
import { useLocation } from "react-router-dom";
import { useCompaign } from "../../context/CompaignProvider";

const Settingmain = () => {
  const [activeTab, setActiveTab] = useState("account");
  const { profile } = useCompaign();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (profile.firstname) {
      setLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  return (
    <>
      <div className="settings-main-conatainer d-flex flex-column gap-24">
        <h5 className="large">Settings</h5>
        <div className="setting-tab-switcher">
          <label
            className={`small text-center cursor-pointer ${activeTab === "account" ? "active" : ""
              }`}
            onClick={() => setActiveTab("account")}
          >
            Account
          </label>
          <label
            className={`small text-center cursor-pointer ${activeTab === "subscription" ? "active" : ""
              }`}
            onClick={() => setActiveTab("subscription")}
          >
            Subscription
          </label>
        </div>

        {activeTab === "account" ? (
          loading ? (
            <div className="profile-main-container d-flex gap-3">
              <div className="profile-left-container d-flex flex-column">
                <div className="profile-dp-container profile-bg-radius">
                  <Skeleton.Avatar size={150} shape="circle" active />
                  <Skeleton paragraph={{ rows: 2 }} active />
                </div>

                <div className="account-setting-container profile-bg-radius">
                  <Skeleton paragraph={{ rows: 3 }} active />
                </div>
              </div>

              <div className="profile-right-container d-flex flex-column">
                <div className="company-info-container profile-bg-radius">
                  <Skeleton paragraph={{ rows: 5 }} active />
                </div>

                <div className="profile-bottom-container profile-bg-radius d-flex space-between">
                  <Skeleton.Button active />
                  <Skeleton.Button active />
                </div>
              </div>
            </div>
          ) : (
            <ProfileComponent />
          )
        ) : (
          <Settingssubscription />
        )}
      </div>
    </>
  );
};

export default Settingmain;
