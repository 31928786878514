import React, { useEffect, useState } from "react";
import CampaignTable from "../tables/campaignTable";
import getCampaignSends from "../../utils/compaignList/getCampaignSends";
import { IoMdLock } from "react-icons/io";
import { usePlans } from "../../context/PlansProvider";

const Emailanalytics = () => {
  const { activePlan } = usePlans();
  const [stats, setStats] = useState({
    today_sends: 0,
    last_day_sends: 0,
  });

  const currentDate = new Date();

  // Get month, day, and year with leading zeros
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const year = currentDate.getFullYear();

  // Format the date as MM/DD/YYYY
  const formattedDate = `${day}/${month}/${year}`;

  useEffect(() => {
    const fetchStats = async () => {
      const result = await getCampaignSends();
      if (result.success) {
        setStats({
          today_sends: result?.today_sends,
          last_day_sends: result.last_day_sends,
        });
      }
    };
    fetchStats();
  }, []);

  return (
    <>
      <div className="email-analytics-main d-flex space-between">
        <div className="email-analytics-left d-flex flex-column">
          <h4 className="elarge">Email Analytics</h4>

          {activePlan?.subscription_name ? (
            <div className="camp-main h-center">
              <div className="w-full d-flex flex-column">
                {/* <h4 className="elarge">Campaign Name</h4> */}
                <div className="inner-table d-flex flex-column ">
                  <CampaignTable />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full d-flex v-center flex-column h-center">
              <IoMdLock size={42} />
              <div className="text-center campaign-noplan-text p-4">
                Subscription is required for Campaign Table
              </div>
            </div>
          )}
        </div>

        <div className="email-analytics-right  d-flex flex-column">
          <p className="v-center gap-2 med">
            <img src="../../assets/icons/calender.svg" alt="" />
            Today ({formattedDate})
          </p>
          <div className="sends-divs gap-16 d-flex flex-column">
            <h6 className="medium-head">Total Sends</h6>
            <p>{stats.today_sends}</p>
          </div>
          <div className="sends-divs gap-16 d-flex flex-column">
            <h6 className="medium-head">Last Day Sends</h6>
            <p>{stats.last_day_sends}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emailanalytics;
