const getImportedRecordsNumber = async (file) => {
    const tokens = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/get-csv-record-number`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${tokens}`
            },
            body: formData
        });
        const result = await response.json();
        
        if (response.ok) {
            return { success: true, number_of_records: result.number_of_records };
        } else {
            return { success: false, message: result.detail };
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` };
    }
};

export default getImportedRecordsNumber;
