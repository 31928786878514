import React from "react";
import Pricingcard from "../shared/pricingcard";

const TogglePricing = () => {
  const [on, setOnState] = React.useState(false);
  const toggle = () => setOnState((o) => !o);

  const monthlyPricingPlans = [
    {
      id: 1,
      name: "Base",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "5,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "10 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "1 AI Written Section/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "200 Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Basic insights on opens and clicks</span>,

        },
        {
          id: 6,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Standard Email Support</span>,
        },
        {
          id: 7,
          enable: false,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: false,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 50,
      button: "Get Started",
    },
    {
      id: 2,
      name: "Pro",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "15,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "20 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "3 AI Written Sections/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "400 Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Advanced analytics insights on opens and clicks</span>,
        },
        {
          id: 6,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Standard Email Support</span>,
        },
        {
          id: 7,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 75,
      button: "Get Started",
    },
    {
      id: 3,
      name: "Premium",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "30,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "40 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "Unlimited AI Written Sections/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "Unlimited Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>Advanced Analytics</span>
          ),
        },
        {
          id: 6,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>High Priority Support</span>
          ),
        },
        {
          id: 7,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 120,
      button: "Get Started",
    },
  ];

  const annuallyPricingPlans = [
    {
      id: 1,
      name: "Base",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "5,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "10 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "1 AI Written Section/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "200 Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Basic insights on opens and clicks</span>,

        },
        {
          id: 6,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Standard Email Support</span>,
        },
        {
          id: 7,
          enable: false,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: false,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 550,
      button: "Get Started",
    },
    {
      id: 2,
      name: "Pro",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "15,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "20 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "3 AI Written Sections/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "400 Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Advanced analytics insights on opens and clicks</span>,
        },
        {
          id: 6,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>Standard Email Support</span>,
        },
        {
          id: 7,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 850,
      button: "Get Started",
    },
    {
      id: 3,
      name: "Premium",
      bullets: [
        {
          id: 1,
          enable: true,
          bullet: "30,000 Emails/Month",
        },
        {
          id: 2,
          enable: true,
          bullet: "40 AI Templates/Month",
        },
        {
          id: 3,
          enable: true,
          bullet: "Unlimited AI Written Sections/Campaign",
        },
        {
          id: 4,
          enable: true,
          bullet: "Unlimited Word Length Limit/ Email",
        },
        {
          id: 5,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>Advanced Analytics</span>
          ),
        },
        {
          id: 6,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>High Priority Support</span>
          ),
        },
        {
          id: 7,
          enable: true,
          bullet: (
            <span style={{ fontWeight: "bold" }}>
              Multi Template AI Campaigns
            </span>
          ),
        },
        {
          id: 8,
          enable: true,
          bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
        },
      ],
      price: 1400,
      button: "Get Started",
    },
  ];
  
  return (
    <>
      <div className="toogle-div v-center">
        <label className="elarge text-darkpurple">Bill Monthly</label>

        <button className={on ? "on" : "off"} on={on} onClick={toggle}>
          <span className="pin" />
        </button>

        <label className="large text-darkpurple">Bill Annually</label>
      </div>

      <div className={`cards-wrapper`}>
        {on
          ? annuallyPricingPlans.map((item) => (
              <Pricingcard item={item} annual={true} />
            ))
          : monthlyPricingPlans.map((item) => <Pricingcard item={item} />)}
      </div>
    </>
  );
};

export default TogglePricing;
