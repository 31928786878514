const cancelSubscription = async () => {
    const tokens = sessionStorage.getItem("token");
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/delete-subscription`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens}`,
          },
        }
      );
      const result = await response.json();
  
      if (response.ok) {
        return { success: true, message: "Plan Unsubscribed Successfully!" };
      } else {
        return { success: false, message: result.detail };
      }
    } catch (e) {
      return { success: false, message: `Something went wrong: ${e}` };
    }
  };
  
  export default cancelSubscription;
  