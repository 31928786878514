export const formatTimeAgo = (date) => {
  const now = new Date();
  const pastDate = new Date(date);
  const diff = now - pastDate; // Difference in milliseconds
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    if (days === 1) return '1 day ago';
    return `${days} days ago`;
  }
  if (hours > 0) {
    if (hours === 1) return '1 hour ago';
    return `${hours} hours ago`;
  }
  if (minutes > 0) {
    if (minutes === 1) return '1 minute ago';
    return `${minutes} minutes ago`;
  }
  if (seconds > 0) {
    if (seconds === 1) return '1 second ago';
    return `${seconds} seconds ago`;
  }

  return 'just now';
};
