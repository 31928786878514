import React from 'react'

const NotFound = () => {
  return (
    <div className='page-404'>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  )
}

export default NotFound