import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate, token]);

  if (!token) {
    return null;
  }

  return (
    <div>
      {Component}
    </div>
  );
};

export default Protected;