import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Texteditor from "./texteditor";
import SelectFileModal from "../modals/selectFileModal";
import { useCompaign } from "../context/CompaignProvider";
import { MdDelete } from "react-icons/md";
import { Skeleton, Tooltip } from "antd";
import { usePlans } from "../context/PlansProvider";

const Emailtemplate = ({
  hide,
  modalclass,
  loader,
  selected,
  previewMode,
  editorContent,
  setEditorContent,
  setSelected,
  emailBody,
  setEmailBody,
  index,
  sender,
  textColor,
  linkColor,
  bgColor,
  fontFamily,
  wordCount,
  setWordCount,
}) => {
  const [isfilemodalOpen, setisfilemodalOpen] = useState(false);
  const { subject, setSubject } = useCompaign();
  const { activePlan } = usePlans();
  const { setColumns } = useCompaign();

  const showfileModal = () => {
    setisfilemodalOpen(true);
  };

  useEffect(() => {
    if (subject === "") {
      const sessionSubject = sessionStorage.getItem("subject");
      if (sessionSubject) {
        setSubject(sessionSubject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    sessionStorage.setItem("subject", subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  return (
    <>
      <div
        className={`start-emailconatainer ${modalclass ? "modalclass" : ""} `}
      >
        <div className="email-container-top h-center flex-column">
          <div className="email-from-div v-center space-between">
            <span className="v-center">
              <label className="small text-gray">From</label>
              <input
                type="email"
                value={sender}
                // onChange={(e) => setSender(e.target.value)}
                placeholder="Emailsender@gmail.com"
                className="email-input from-input"
                disabled
              />
            </span>

            <Icon
              icon="ri:more-2-fill"
              width={24}
              height={24}
              color="#7D7D7D"
              className="cursor-pointer email-temp-icons"
            />
          </div>

          <div className="email-from-div">
            <span className="v-center">
              <label className="small text-gray">To</label>
              {!previewMode ? (
                selected !== "" ? (
                  <div className="selected-contact-file">
                    <div className={`button-purple-transparent px-3 py-1`}>
                      {selected.length > 30 ? `${selected.slice(0, 30)}...` : selected}
                    </div>
                   
                    <Tooltip title="Remove CSV">
                      <MdDelete
                        className="del-icon"
                        onClick={() => {
                          setColumns([]);
                          setSelected("");
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title="Select CSV File">
                    <button
                      className={`button-purple-transparent ${hide} px-3`}
                      onClick={showfileModal}
                    >
                      Select recipients
                    </button>
                  </Tooltip>
                )
              ) : (
                <input
                  type="text"
                  placeholder="adam@gmail.com"
                  value={editorContent.email}
                  className={`email-input from-input1`}
                  disabled
                />
              )}
            </span>
          </div>

          <div className="email-from-div v-center space-between">
            <span className="v-center">
              <label className="small text-gray">Subject</label>
              <input
                type="text"
                placeholder="Enter your email subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  sessionStorage.setItem("subject", e.target.value);
                }}
                className="email-input"
                disabled={previewMode ? true : false}
              />
            </span>

            {!previewMode && <Tooltip title="Subject must be less than 30 words">
              <Icon
                icon="ic:outline-info"
                width={24}
                height={24}
                color="#28104E"
                className="cursor-pointer email-temp-icons"
              />
            </Tooltip>}
          </div>
        </div>

        {/* Email Body */}
        <div className="text-editor-div">
          {loader ? (
            <div className="loader-bg">
              <Skeleton.Avatar
                active={true}
                shape="square"
                size={"large"}
                style={{
                  width: "70vw",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "400px",
                }}
              ></Skeleton.Avatar>
              <div className="loader" />
            </div>
          ) : previewMode ? (
            <div
              className="preview-container"
              style={{
                backgroundColor: bgColor,
                fontFamily: fontFamily,
              }}
              dangerouslySetInnerHTML={{ __html: editorContent.response_text }}
            ></div>
          ) : (
            <Texteditor
              previewMode={previewMode}
              emailBody={emailBody ? emailBody : editorContent}
              index={index}
              setEmailBody={setEmailBody ? setEmailBody : setEditorContent}
              textColor={textColor}
              linkColor={linkColor}
              bgColor={bgColor}
              setWordCount={setWordCount}
              fontFamily={fontFamily}
            />
          )}
        </div>
      </div>

      {!previewMode &&
        activePlan?.subscription_name &&
        activePlan?.subscription_name?.split(" ")[0] !== "Premium" && (
          <p
            className={`word-counts ${activePlan.words_per_email < wordCount[index] ? "text-danger" : ""
              }`}
          >
            ({wordCount[index]}/{activePlan.words_per_email}) words
          </p>
        )}

      <SelectFileModal
        isfilemodalOpen={isfilemodalOpen}
        setisfilemodalOpen={setisfilemodalOpen}
        listTable={true}
        setSelected={setSelected}
      />
    </>
  );
};

export default Emailtemplate;
