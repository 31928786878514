import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCompaign } from "../../../context/CompaignProvider";
import { Modal, Pagination, Tooltip } from "antd";

const CampaignDetails = () => {
  const { campaign_name } = useParams();
  const { campaigndata, setCampaignData } = useCompaign();
  const [totalTemplates, setTotalTemplates] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const selectedCampaign = campaigndata?.find(
    (campaign) => campaign.campaign_name === campaign_name
  );

  // Combine the multiple arrays in "Contacts Data"
  const combinedContactsData = selectedCampaign?.["Contacts Data"]?.reduce(
    (acc, val) => acc.concat(val),
    []
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = combinedContactsData?.slice(startIndex, endIndex);

  const overview = [
    {
      id: 1,
      icon: "../../../../assets/icons/receipents.png",
      label: "Total Recipients",
      value: selectedCampaign ? selectedCampaign["Total Contacts"] : 0,
    },
    {
      id: 2,
      icon: "../../../../assets/icons/clicks.png",
      label: "Click",
      value: selectedCampaign ? selectedCampaign["Total Count"] : 0,
    },
    {
      id: 3,
      icon: "../../../../assets/icons/templates.png",
      label: "User Templates",
      value: selectedCampaign ? selectedCampaign.template_data.length : 0,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedTimeUpperCase = formattedTime.replace(/am|pm/i, match => match.toUpperCase());

    return `${formattedDate} at ${formattedTimeUpperCase}`;
  };

  useEffect(() => {
    setCampaignData(JSON.parse(sessionStorage.getItem("compaignList")));
  }, [setCampaignData]);

  if (!selectedCampaign) {
    return <p>Campaign not found</p>;
  }

  return (
    <>
      <div className="campaign-details">
        {/* Compaign Header */}
        <div className="details-header">
          <h3>{selectedCampaign.campaign_name}</h3>

          <p>
            {selectedCampaign["Total Contacts"]} receipents are involved in this
            campaign!
          </p>

          <div className="compaign-created">
            <span>Created</span>
            {formatDate(selectedCampaign["Sending Date"])}
          </div>
        </div>

        {/* Compaign Overview */}
        <div className="compaign-detail-overview">
          <div className="overview-title">Overview</div>

          <div className="overview-container">
            {overview.map((item) =>
              item.id === 3 ? (
                <Tooltip key={item.id} title="Templates Used in campaign">
                  <div
                    key={item.id}
                    className={`overview-item cursor-pointer`}
                    onClick={() => {
                      if (item.id === 3) {
                        setTotalTemplates(true);
                      }
                    }}
                  >
                    <div className="overview-icon">
                      <img src={item.icon} alt={item.label} />
                    </div>

                    <div className="overview-label">{item.label}</div>

                    <p className="overview-counts">
                      {item.id === 1
                        ? `${selectedCampaign["Total Contacts"]} contacts`
                        : item.id === 2
                          ? `${selectedCampaign["Total Count"]} views`
                          : item.id === 3
                            ? `${selectedCampaign.template_data.length} templates`
                            : item.value}
                    </p>
                  </div>
                </Tooltip>
              ) : (
                <div
                  key={item.id}
                  className={`overview-item`}
                  onClick={() => {
                    if (item.id === 3) {
                      setTotalTemplates(true);
                    }
                  }}
                >
                  <div className="overview-icon">
                    <img src={item.icon} alt={item.label} />
                  </div>

                  <div className="overview-label">{item.label}</div>

                  <div className="overview-counts">
                    {item.id === 1
                      ? `${selectedCampaign["Total Contacts"]} contacts`
                      : item.id === 2
                        ? `${selectedCampaign["Total Count"]} views`
                        : item.id === 3
                          ? `${selectedCampaign.template_data.length} templates`
                          : item.value}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* Receipents details */}
        <div className="receipent-data">
          <div className="receipent-title">Emails</div>

          <div className="receipent-table ">
            <table className="camp-table">
              <thead>
                <tr className="camp-email-thead ">
                  <th>Recipient's Name</th>
                  <th>Status</th>
                  <th>Date of Creation</th>
                  <th>Template Used</th>
                </tr>
              </thead>

              <tbody>
                {selectedCampaign["Contacts Data"] !== "" &&
                  visibleData?.map((data, index) => (
                    <tr
                      key={index}
                      className="send-email-tbody"
                      style={{ cursor: "pointer" }}
                    >
                      <td>{data.email}</td>
                      <td>
                        <div className="success-badge">{data.status}</div>
                      </td>
                      <td>{formatDate(selectedCampaign["Sending Date"])}</td>
                      <td>{data.template_name.length > 30 ? `${data.template_name.slice(0, 30)}...` : data.template_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {(selectedCampaign["Contacts Data"] === "" ||
            campaigndata.length < 1) && (
              <div className="text-center text-black montserrat-family w-full p-2">
                No campaigns to show here
              </div>
            )}

          <div className="contacts-bottom-div d-flex space-between align-items-center gap-2 pt-4">
            <label className="small">{`Showing ${startIndex + 1} to ${endIndex > selectedCampaign["Contacts Data"].length
              ? selectedCampaign["Contacts Data"].length
              : endIndex
              } of ${selectedCampaign["Contacts Data"].length} results`}</label>

            <Pagination
              // className="camp-pagination"
              defaultCurrent={1}
              total={selectedCampaign["Contacts Data"].length}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <Modal
        open={totalTemplates}
        onOk={() => setTotalTemplates(false)}
        onCancel={() => setTotalTemplates(false)}
        className="campaign-details-modal"
        // centered={true}
        width={1200}
      >
        <div className="details-container">
          <h4 className="font-bold">Total Templates Used</h4>
          <div className="detail-body">
            {selectedCampaign.template_data.map((item, index) => (
              <div key={index} className="detail-template w-full">
                <div className="template-head">
                  <h4 className="template-title">{item.template_name}</h4>
                  <span>({item.template_count} views)</span>
                </div>

                <p dangerouslySetInnerHTML={{ __html: item.template_body }} />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CampaignDetails;
