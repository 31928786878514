import React, { useEffect, useState } from "react";
import Variationcard from "../../../shared/variationcard";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { usePlans } from "../../../context/PlansProvider";
import { useCompaign } from "../../../context/CompaignProvider";

const Createcampaign = () => {
  const { activePlan } = usePlans();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const [isError, setIsError] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const {
    setCompaignMail,
    setSubject,
    aiPrompts,
    setAIPrompts,
    setSelectedFile,
    setColumns,
  } = useCompaign();
  const [cardsRange, setCardsRange] = useState(0);

  const [variationCards, setVariationCards] = useState([
    { id: 1, prompt: "", error: "", response: "" },
  ]);

  const navigateToCreate = () => {
    setAIPrompts(variationCards)
    if (selected === "textarea") {
      if (textareaValue.trim() === "") {
        setIsError(true);
      } else {
        setCompaignMail([{ template_name: "", template_body: textareaValue }]);
        sessionStorage.setItem(
          "template",
          JSON.stringify([{ template_name: "", template_body: textareaValue }])
        );
        navigate("/dashboard/startcampaign");
      }
    } else {
      const updatedVariationCards = variationCards.map((card) => {
        if (card.id === selected) {
          const error =
            card.prompt.trim() === ""
              ? "Campaign text variation is required!"
              : "";
          return { ...card, error };
        }
        return card;
      });

      setVariationCards(updatedVariationCards);
      const cardSelected = updatedVariationCards.find(
        (card) => card.id === selected && !card.error
      );
      if (cardSelected) {
        setCompaignMail([
          { template_name: "", template_body: cardSelected.response },
        ]);
        sessionStorage.setItem(
          "template",
          JSON.stringify([
            { template_name: "", template_body: cardSelected.response },
          ])
        );
        navigate("/dashboard/startcampaign");
      }
    }
  };

  const handleSkip = () => {
    sessionStorage.removeItem("template");
    sessionStorage.removeItem("subject");
    setCompaignMail([
      {
        template_name: "",
        template_body: "",
      },
    ]);
    setSubject("");
    navigate("/dashboard/startcampaign");
  };

  const addVariationCard = () => {
    setVariationCards([
      ...variationCards,
      { id: variationCards.length + 1, prompt: "", error: "", response: "" },
    ]);
  };

  const removeVariationCard = () => {
    setVariationCards((prevCards) => {
      const updatedCards = prevCards.filter((card) => card.id !== selected);
      const reassignedCards = updatedCards.map((card, index) => ({
        ...card,
        id: index + 1,
      }));
      return reassignedCards;
    });
    setSelected(1);
  };

  const updateVariationCard = (id, prompt, error, response) => {
    setVariationCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, prompt, error, response } : card
      )
    );
  };

  useEffect(() => {
    sessionStorage.removeItem("template");
    setCompaignMail([""]);
    setSubject("");
    setSelectedFile("");
    setColumns([]);
    sessionStorage.removeItem("subject");
    sessionStorage.removeItem("textColor");
    sessionStorage.removeItem("bgColor");
    sessionStorage.removeItem("linkColor");

    if (activePlan.ai_written_section_allowed) {
      if (activePlan.ai_written_section_allowed === "No Limit") {
        setCardsRange(Infinity);
      } else {
        setCardsRange(activePlan.ai_written_section_allowed - 1);
      }
    }

    if (aiPrompts.length > 0) {
      setVariationCards(aiPrompts.map((item) => ({ ...item, error: "" })));
      setSelected(aiPrompts[0].id);
    }
     // eslint-disable-next-line
  }, []);

  return (
    <div className="create-campaign-main">
      <h4 className="elarge">Create Campaign</h4>

      <div className="text-variation-container m-auto">
        <h4 className="elarge">Create your own Template</h4>

        <div className="text-variation-inner">
          <div className="variation-heading v-center gap-8">
            <p className="body-large text-white v-center h-center">
              {variationCards.length}
            </p>
            <label>Generate Campaign Template with AI</label>
          </div>

          <div className="v-card v-center space-between gap-36">
            <div className="w-full">
              {variationCards.map((card) => (
                <div key={card.id} className="variation-card-wrapper w-100">
                  <div className="relative">
                    <Variationcard
                      id={card.id}
                      selected={selected === card.id}
                      setSelected={setSelected}
                      variationCards={variationCards}
                      updateVariationCard={updateVariationCard}
                      aiPrompts={aiPrompts}
                      setAIPrompts={setAIPrompts}
                    />
                  </div>

                  {card.error && (
                    <div className="text-error-compaign">{card.error}</div>
                  )}
                </div>
              ))}
            </div>

            <div className="d-flex flex-md-column v-center gap-2">
              <Tooltip title="Add Generate Section">
                <img
                  src="../../../assets/images/dashboardimages/add.png"
                  alt="Add"
                  className={`cursor-pointer plus_template ${
                    variationCards.length > cardsRange ? "btn-disable" : ""
                  }`}
                  onClick={addVariationCard}
                />
              </Tooltip>

              <Tooltip title="Remove Generate Section">
                <img
                  src="../../../assets/images/dashboardimages/minus-template.png"
                  alt="Add"
                  className={`cursor-pointer plus_template ${
                    variationCards.length < 2 ? "btn-disable" : ""
                  }`}
                  onClick={removeVariationCard}
                />
              </Tooltip>
            </div>
          </div>

          <p className="p-med text-center my-4 segoe-family"> OR</p>

          <div className="own-temp-div gap-16 d-flex flex-column">
            <label>Paste Your Own Template Here</label>
            <textarea
              rows="3"
              value={textareaValue}
              onClick={() => setSelected("textarea")}
              style={{ borderColor: selected === "textarea" ? "#28104e" : "" }}
              onChange={(e) => setTextareaValue(e.target.value)}
            ></textarea>
          </div>

          {isError && textareaValue.trim() === "" && (
            <p className="error-message mt-2">
              Please select any one card or paste your own template
            </p>
          )}

          <div className="variation-bottom d-flex gap-16">
            <button onClick={() => navigate("/dashboard")}>Cancel</button>
            <button className="button-purple-linear" onClick={handleSkip}>
              Skip
            </button>
            <button
              className="v-center h-center gap-8"
              onClick={navigateToCreate}
            >
              Start Campaign{" "}
              <img src="../../../assets/icons/glitter.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createcampaign;
