const cancelSocket = async (taskid) => {
    const tokens = sessionStorage.getItem("token")

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/cancel-task/${taskid}`,
            {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${tokens}`
                },
            }
        );

        const result = await response.json();
        if (response.ok) {
            return { success: true, data: result.message }
        } else {
            return { success: false, message: result.detail }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }

    }
}

export default cancelSocket