import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer, Modal } from "antd";
import { useCompaign } from "../context/CompaignProvider";

const Sidebar = ({ isDrawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const { setCompaignMail, setSubject, setSelectedFile, setColumns } = useCompaign();
  const [showPopup, setShowPopup] = useState(false);
  const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(window.innerWidth < 1023);

  const handleOk = () => setShowPopup(false);
  const handleCancel = () => setShowPopup(false);

  const handleLogout = () => {
    const keysToRemove = ["token", "first_name", "last_name", "email", "avatar", "template", "subject", "textColor", "bgColor", "linkColor"];
    keysToRemove.forEach(key => sessionStorage.removeItem(key));
    setCompaignMail([""]);
    setSubject('');
    setSelectedFile('');
    setColumns([]);
    handleCancel();
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => setIsMobileSidebarVisible(window.innerWidth < 1023);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navLinks = [
    { to: "/dashboard", icon: "material-symbols:send-outline-rounded", text: "Campaigns" },
    { to: "/templates", icon: "material-symbols:description-outline", text: "Templates" },
    { to: "/list-of-contacts", icon: "material-symbols:contacts-outline", text: "List of Contacts" },
    { to: "/email-analytics", icon: "material-symbols:analytics-outline", text: "Email Analytics" },
    { to: "/subscription", icon: "carbon:purchase", text: "Subscription" },
    { to: "/how-it-works", icon: "ep:guide", text: "How it Works" },
    { to: "/settings", icon: "fluent:settings-24-regular", text: "Settings" }
  ];

  return (
    <>
      <div className="sidebar-main-container">
        <div className="scrollable">
          <div className="sidebar-logo-container h-center">
            <Link to={process.env.REACT_APP_LANDING_URL}>
              <img src="../../assets/images/logo-white.png" alt="logo" className="logo" />
            </Link>
          </div>

          <div className="sidebar-options-container">
            <ul className="d-flex flex-column space-between">
              <div className="top-options">
                {navLinks.map(({ to, icon, text }) => (
                  <li key={to}>
                    <NavLink to={to} className="dashLinks d-flex" activeclassname="active">
                      <Icon icon={icon} width={24} height={24} className="sidebar-icons" />
                      <span>{text}</span>
                    </NavLink>
                  </li>
                ))}
              </div>
              <div className="bottom-options">
                <li>
                  <div onClick={() => setShowPopup(true)} className="dashLinks d-flex">
                    <Icon icon="ri:logout-circle-line" width={24} height={24} className="sidebar-icons" />
                    <span>Logout</span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>

      {isMobileSidebarVisible && (
        <div className="mobile-sidebar">
          <Drawer
            placement="left"
            closable={false}
            onClose={toggleDrawer}
            open={isDrawerOpen}
            className="mobile-menu-drawer"
          >
            <div className="sidebar-container">
              <img
                src={isDrawerOpen ? "../../assets/icons/sidebarArrow-left.png" : "../../assets/icons/sidebarArrow-right.png"}
                alt=""
                className="side-bar-arrow cursor-pointer"
                onClick={toggleDrawer}
              />
              <div className="scrollable">
                <div className="sidebar-logo-container h-center">
                  <Link to={process.env.REACT_APP_LANDING_URL}>
                    <img src="../../assets/images/logo-white.png" alt="logo" className="logo" />
                  </Link>
                </div>
                <div className="sidebar-options-container">
                  <ul className="d-flex flex-column space-between">
                    <div className="top-options">
                      {navLinks.map(({ to, icon, text }) => (
                        <li key={to} onClick={toggleDrawer}>
                          <NavLink to={to} className="dashLinks d-flex" activeclassname="active">
                            <Icon icon={icon} width={24} height={24} className="sidebar-icons" />
                            <span>{text}</span>
                          </NavLink>
                        </li>
                      ))}
                      <li onClick={toggleDrawer}>
                        <div onClick={() => setShowPopup(true)} className="dashLinks d-flex">
                          <Icon icon="ri:logout-circle-line" width={24} height={24} className="sidebar-icons" />
                          <span>Logout</span>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      )}

      <Modal
        open={showPopup}
        onOk={handleOk}
        onCancel={handleCancel}
        className="logout-modal"
        centered
      >
        <div className="modal-content">
          <div className="purple-header"></div>
          <div className="bottom-content v-center flex-column">
            <div className="img-modal-container">
              <img src="../../assets/icons/logoutImg.svg" alt="logoutImg" />
            </div>
            <h4 className="elarge montserrat-family">Are you sure you want to logout?</h4>
            <div className="modal-buttons d-flex gap-16 mt-24">
              <button className="text-darkpurple segoe-family" onClick={handleCancel}>Cancel</button>
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
