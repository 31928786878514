import React, { useEffect, useState } from "react";
// import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import googleAuth from "../utils/googleAuth";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Checkbox } from "antd";
import { notification } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { IoCloseCircle } from "react-icons/io5";

const OAuth = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const token = sessionStorage.getItem("token");
  const alphaNumericKey = generateKey();
  const [api, contextHolder] = notification.useNotification();

  const getUserDetails = async (accessToken) => {
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${accessToken}`
    );
    const data = await response.json();
    return data;
  };

  // const onSuccess = async (credentialResponse) => {
  //   const data = jwtDecode(credentialResponse.credential);
  //   sessionStorage.setItem("sid", alphaNumericKey);
  //   const response = await googleAuth(data);
  //   if (response.success) {
  //     navigate("/dashboard");
  //   } else {
  //     console.log(response.message);
  //   }
  // };

  // const onError = () => {
  //   console.log("something went wrong!");
  // };

  function generateKey() {
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characters = "0123456789";

    let key = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters[randomIndex];
    }
    return key;
  }

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const data = await getUserDetails(credentialResponse.access_token);
      console.log(data.family_name)
      if (data) {
        sessionStorage.setItem("first_name", data.given_name);
        sessionStorage.setItem(
          "last_name",
          data.family_name !== undefined || data.family_name !== null ? data.family_name : ""
        );
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("avatar", data.picture);
        sessionStorage.setItem("sid", alphaNumericKey);
        const response = await googleAuth(data);
        if (response.success) {
          // Generate the key
          navigate("/dashboard");
        } else {
          console.log(response.message);
        }
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const handleChecked = (e) => {
    setIsChecked(e.target.checked); // Update checkbox state
  };

  const handleLoginClick = () => {
    if (isChecked) {
      setLoader(true);
      login();
    } else {
      api.open({
        message: "Please agree to the Terms & Conditions to proceed.",
        icon: <IoCloseCircle className="false-icon" />,
      });
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate, token]);

  if (token) {
    return null;
  }

  return (
    <>
      <div className="signup-container d-flex space-between">
        <div className="left-conatiner">
          <div className="inner-wrapper">
            <div className="create-acc-container">
              {/* Logo */}
              <Link to={process.env.REACT_APP_LANDING_URL} target="_blank">
                <img
                  src="../../assets/images/logo.png"
                  alt="logo"
                  className="logo"
                />
              </Link>

              {/* Title */}
              <h2 className="signup-title">Welcome Aboard Deuces</h2>

              {/* Description */}
              <p className="auth-desc">
                Send personalized emails, follow-up easily, and track your
                campaigns in real-time.
              </p>

              {/* Hidden google button */}
              {/* <div className="w-full hidden">
                <GoogleLogin
                  onSuccess={onSuccess}
                  onError={onError}
                  useOneTap
                  text="Continue with"
                  size="large"
                  theme="filled"
                  width={700}
                />
              </div> */}

              {/* Google Btn */}
              <button
                onClick={handleLoginClick}
                className={`google-btn ${loader ? "btn-disable" : ""
                  } mt-24 w-400 h-center v-center gap-8`}
              >
                <div className="google-icon">
                  <Icon icon="logos:google-icon" width={32} height={32} />
                </div>
                <p>
                  {loader ? (
                    <div className="google-loading" />
                  ) : (
                    <span>Continue with Google</span>
                  )}
                </p>
              </button>

              <p><Checkbox onChange={handleChecked} />{" "} Agree to our <a href={`${process.env.REACT_APP_LANDING_URL}/terms-and-conditions`}>Terms & Conditions</a></p>
            </div>
          </div>

          <p className="footer-auth">
            © 2024 <Link to="/">Deuces AI</Link>, LLC.
          </p>
        </div>

        <div className="right-container">
          <img src="../../assets/images/other/bg-auth.png" alt="img" />
        </div>
      </div>

      {contextHolder}

    </>
  );
};

export default OAuth;
