import React, { useState } from "react";
import { Modal, notification } from "antd";
import cancelSubscription from "../utils/cancelSubscription";
import { IoCloseCircle } from "react-icons/io5";
import { usePlans } from "../context/PlansProvider";
import planInfo from "../utils/planInfo";

const CancelPlan = ({ toggleCancel, setToggleCancel }) => {
    const api = notification;
    const { setActivePlan } = usePlans();
    const [cancelling, setCancelling] = useState(false);

    const fetchActivePlan = async () => {
        const response = await planInfo();
        if (response.success) {
            setActivePlan(response.data);
        }
    };

    const handleCancel = async () => {
        setCancelling(true)
        const response = await cancelSubscription();

        if (response.success) {
            setToggleCancel(false);
            setCancelling(false)
            api.success({
                message: response.message,
                icon: <img src="../../assets/icons/tick-arrow.svg" alt="tick-arrow" />,
            });
            setActivePlan({});
            fetchActivePlan();
        } else {
            setToggleCancel(false);
            setCancelling(false)
            api.error({
                message: response.message,
                icon: <IoCloseCircle className="false-icon" />,
            });
        }
    };

    return (
        <>
            <Modal
                open={toggleCancel}
                onOk={() => setToggleCancel(false)}
                onCancel={() => setToggleCancel(false)}
                className="credit-modal"
                centered={true}
            >
                <div className="modal-content">
                    <div className="purple-header"></div>
                    <div className="bottom-content v-center flex-column">
                        <img
                            src="../../assets/images/dashboardimages/creditImg.svg"
                            alt="credits"
                            className="w-auto h-auto"
                        />
                        <h4 className="elarge montserrat-family text-center">
                            Are you sure you want to cancel your plan?
                        </h4>

                        <div className="modal-buttons d-flex gap-16 mt-24">
                            <button
                                className="text-darkpurple segoe-family"
                                onClick={() => setToggleCancel(false)}
                            >
                                No
                            </button>
                            <button className={`d-flex h-center v-center gap-2 ${cancelling ? 'btn-disable' : ''}`} onClick={handleCancel}>

                                {cancelling ?
                                    <span className="btn-spinner"></span>
                                    : "Yes"}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CancelPlan;
