import React, { useState, useEffect } from "react";
import { Checkbox, Modal, notification } from "antd";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";
import sendEmails from "../utils/sendEmails";
import { useCompaign } from "../context/CompaignProvider";

const SendEmailModal = ({
  isEmailModalOpen,
  setIsEmailModalOpen,
  filterEmails,
  editorContent,
  fontFamily,
  bgColor,
  subject,
  scheduleDate,
  scheduleTime,
  handleCloseParentModal,
  selectedFile,
  setIsModalOpen,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disable, setDisable] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { compaignMail } = useCompaign();

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedSelectedRows = filterEmails.map(() => newSelectAll);
    setSelectedRows(updatedSelectedRows);
  };

  const toggleRowSelect = (index) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = !updatedSelectedRows[index];
    setSelectedRows(updatedSelectedRows);
  };

  function toLocalISOString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const handleOk = async () => {
    setDisable(true);
    const selectedEditorContent = editorContent.filter((_, idx) =>
      selectedRows[idx]
    );

    if (selectedEditorContent.length > 0) {
      const now = new Date();
      const formattedDate = toLocalISOString(now);
      const minAllowedDateTime = new Date(now.getTime() + 15 * 60000);
      const minAllowedDateString = moment(minAllowedDateTime).format("YYYY-MM-DD");
      const minAllowedTimeString = moment(minAllowedDateTime).format("HH:mm");
      const date = scheduleDate ? moment(scheduleDate.$d).format("YYYY-MM-DD") : null;
      const time = scheduleTime;
      let datetime = null;

      if (date && time) {
        datetime = `${date}T${time}`;
        const scheduledDateTime = new Date(datetime);
        const scheduledDateString = moment(scheduledDateTime).format("YYYY-MM-DD");
        const scheduledTimeString = moment(scheduledDateTime).format("HH:mm");
        const isTooEarly = scheduledDateString < minAllowedDateString ||
          (scheduledDateString === minAllowedDateString && scheduledTimeString < minAllowedTimeString);

        if (isTooEarly) {
          api.open({
            message: `Scheduled time must be at least 15 minutes forward from now!`,
            icon: <IoCloseCircle className="false-icon" />,
          });
          setDisable(false);
          handleCloseParentModal();
          return;
        }
      } else {
        datetime = formattedDate;
      }

      const data = selectedEditorContent.map(item => ({
        email: item.email,
        body: `<div style="background-color: ${bgColor}; font-family:${fontFamily};">${item.response_text}</div>`,
        subject: subject,
        template_name: item.template_name,
      }));

      const templates_data = compaignMail.map((item) => {
        return {
          ...item,
          template_name:
            item.template_name === "" ? subject : item.template_name,
        };
      });

      const campaign_name = subject;

      try {
        const result = await sendEmails(
          data,
          datetime,
          formattedDate,
          campaign_name,
          templates_data,
          selectedFile
        );

        if (result.success) {
          api.open({
            message: result.message,
            icon: (
              <img src="../../assets/icons/tick-arrow.svg" alt="tick-arrow" />
            ),
          });

          setIsEmailModalOpen(false);
          setIsModalOpen(false);
          setDisable(false);
          handleCloseParentModal();
        } else if (result.message === "User not authorized") {
          window.location.href =
            `${process.env.REACT_APP_URL}/authorize`;
          setIsEmailModalOpen(false);
          handleCloseParentModal();
          setDisable(false);
        } else {
          api.open({
            message: result.message,
            icon: <IoCloseCircle className="false-icon" />,
          });
          setIsEmailModalOpen(false);
          handleCloseParentModal();
          setDisable(false);
        }
      } catch (error) {
        api.open({
          message: `An error occurred: ${error.message}`,
          icon: <IoCloseCircle className="false-icon" />,
        });
        setIsEmailModalOpen(false);
        handleCloseParentModal();
        setDisable(false);
      }
    } else {
      api.open({
        message: `No Recepient is Selected!`,
        icon: <IoCloseCircle className="false-icon" />,
      });
      setDisable(false);
    }
  };

  const handleCancel = () => {
    setIsEmailModalOpen(false);
  };

  useEffect(() => {
    setSelectedRows(Array(filterEmails?.length).fill(false));
    setSelectAll(false);
  }, [isEmailModalOpen, filterEmails?.length]);

  useEffect(() => {
    const allRowsSelected = selectedRows.every((row) => row);
    setSelectAll(allRowsSelected);
  }, [selectedRows]);

  return (
    <>
      <Modal
        open={isEmailModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="sendEmail-modal"
        centered={true}
        width={"35%"}
      >
        <div className="send-mail-container">
          <div className="email-modal-header">
            <h5 className="large text-md-black segoe-family text-center">
              Are you sure you're ready to send this email
            </h5>
          </div>

          <div className="preview-modal-content">
            <p className="sendbox-range">
              {selectedRows.filter(Boolean).length} out of{" "}
              {filterEmails?.length} selected
            </p>

            {/* Custom Table */}
            <div className="main-table">
              <table className="table-head">
                <thead>
                  <tr className="send-email-thead ">
                    <th>
                      <div className="email-check-div v-center gap-4 ">
                        <Checkbox
                          id="customCheckboxSelectAll"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        >
                          Email
                        </Checkbox>
                      </div>
                    </th>
                    <th>Name</th>
                    {/* <th>Number</th> */}
                  </tr>
                </thead>
              </table>

              <div className="table-body">
                <table>
                  <tbody>
                    {filterEmails?.map((data, index) => (
                      <tr key={index} className="send-email-tbody">
                        <td>
                          <div className="email-check-div v-center gap-4">
                            <Checkbox
                              checked={selectedRows[index]}
                              onChange={() => toggleRowSelect(index)}
                            >
                              {data.email}
                            </Checkbox>
                          </div>
                        </td>
                        <td>{data.name}</td>
                        {/* <td>{data.number}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="email-modal-bottom v-center space-between">
            <p className="segoe-family">
              {filterEmails?.length} recipients in your contacts list
            </p>

            <div className="email-right-btns d-flex gap-3">
              <button
                className="button-purple-transparent"
                onClick={handleCancel}
              >
                Close
              </button>

              <button
                className="button-purple-dark px-3 py-2 d-flex v-center h-center"
                onClick={handleOk}
                style={{
                  opacity: disable ? 0.6 : 1,
                  cursor: disable ? "not-allowed" : "pointer",
                }}
                disabled={disable}
              >
                {disable ? (
                  <div className="d-flex h-center v-center gap-2">
                    <div className="send-loading" />
                    <span>Sending...</span>
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {contextHolder}
    </>
  );
};

export default SendEmailModal;
