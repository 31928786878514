import React, { createContext, useState, useContext } from "react";
import getTemplates from "../utils/templates/getTemplates";

const CompaignContext = createContext();

export const useCompaign = () => useContext(CompaignContext);

export const CompaignProvider = ({ children }) => {
  const [columns, setColumns] = useState([]);
  const [colError, setColError] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [campaigndata, setCampaignData] = useState([]);
  // All Templates
  const [templates, setTemplates] = useState([]);

  // Email Content
  const [subject, setSubject] = useState("");
  const [compaignMail, setCompaignMail] = useState([]);
  const [aiPrompts, setAIPrompts] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [profile, setProfile] = useState({});
  const [previewinstance, setPreviewInstance] = useState();
  const [sid, setSid] = useState("");
  const [compaignListItem, setcompaignListItem] = useState({
    compaign_name: "",
    template_data: [
      {
        template_name: "",
        template_body: "",
      },
    ],
    filename: "",
  });

  // Generated Preview Emails
  const [editorContent, setEditorContent] = useState([]);

  const handleTemplates = async () => {
    const response = await getTemplates();
    if (response.success) {
      setTemplates(response.data);
    }
  };

  return (
    <CompaignContext.Provider
      value={{
        columns,
        setColumns,
        templates,
        setTemplates,
        handleTemplates,
        editorContent,
        setEditorContent,
        compaignMail,
        setCompaignMail,
        subject,
        setSubject,
        aiPrompts,
        setAIPrompts,
        colError,
        setColError,
        selectedFile,
        setSelectedFile,
        campaigndata,
        setCampaignData,
        togglePopup,
        setTogglePopup,
        // Compaign List Item
        compaignListItem,
        setcompaignListItem,
        profile,
        setProfile,
        previewinstance,
        setPreviewInstance,
        sid, setSid
      }}
    >
      {children}
    </CompaignContext.Provider>
  );
};
