const sendEmails = async (
  data,
  datetime,
  formattedDate,
  campaign_name,
  templates_data,
  selectedFile
) => {
  const tokens = sessionStorage.getItem("token");
  const mailsArray = {
    data,
    campaign_name,
    template_data: templates_data,
    filename: selectedFile,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/v2/send-mail?send_time=${datetime}&user_current_time=${formattedDate}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
        body: JSON.stringify(mailsArray),
      }
    );

    const result = await response.json();
    if (response.ok) {
      return { success: true, message: result.message };
    } else {
      return { success: false, message: result.detail };
    }
  } catch (e) {
    return { success: false, message: `Something went wrong: ${e}` };
  }
};

export default sendEmails;
