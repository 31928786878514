import React from "react";
import PurchaseModal from "../modals/purchaseModal";
import TogglePricing from "./TogglePricing";

const Subscription = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="subscription-main-container">
        {/* <h5 className="large segoe-family">Subscription</h5> */}

        <p className="large text-center text-darkpurple mt-1">
          Easy to learn. Easy to use. Pick a plan and get started.
        </p>

        <div className="pricing-comp v-center flex-column">
          <TogglePricing />
        </div>

        <div className={`subscription-bottom h-center ${props.hide}`}>
          <button className="segoe-family" onClick={showModal}>
            Purchase Templates
          </button>
        </div>
      </div>

      <PurchaseModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default Subscription;
