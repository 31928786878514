import React, { createContext, useState, useContext } from "react";

const PlansContext = createContext();

export const usePlans = () => useContext(PlansContext);

export const PlansProvider = ({ children }) => {
  const [activePlan, setActivePlan] = useState({});

  // {
  //   plan_name: "Pro",
  //   emails_per_month: "15,000",
  //   duration:"month",
  //   details: [
  //     "Personalization compaigns — 3 AI Written section",
  //     "20 AI templates/ month (2 per campaign)",
  //     "400 words per email",
  //   ],
  // }
  
  return (
    <PlansContext.Provider value={{ activePlan, setActivePlan }}>
      {children}
    </PlansContext.Provider>
  );
};
