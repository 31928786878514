import React, { useState, useRef, useEffect } from "react";
import { Modal } from "antd";
import { BsFiletypeCsv } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import getImportedRecordsNumber from "../utils/getImportedRecordsNumber";
import uploadListOfContacts from "../utils/uploadListOfContacts";
import { notification } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import Listofcontacttable from "../dashboard/tables/listofcontacttable";
import uploadGoogleSheet from "../utils/uploadGoogleSheet";
import { useCompaign } from "../context/CompaignProvider";
import getColumns from "../utils/getColumns";

const SelectFileModal = ({
  isfilemodalOpen,
  setisfilemodalOpen,
  listTable,
  setSelected,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const { setColumns } = useCompaign();
  const [activeButton, setActiveButton] = useState("importCSV");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [trackRecordCount, setTrackRecordCount] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [url, setUrl] = useState("");
  const [asgname, setAsgname] = useState("");
  const [disable, setDisable] = useState(false);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleOk = async (e) => {
    e.preventDefault();
    setDisable(true);

    if (activeButton === "importCSV") {
      if (!selectedFile) {
        api.open({
          message: "CSV File is required!",
          icon: <IoCloseCircle className="false-icon" />,
        });
        setDisable(false);
      }
      else {
        const response = await uploadListOfContacts(
          selectedFile,
          selectedFileName
        );
        if (response.success) {
          // setActiveButton("showCSV");
          if (listTable) {
            setSelected(selectedFileName);
          }
          const result = await getColumns(selectedFileName);
          if (result.success) {
            setColumns(result.data);
          }
          setSelectedFile(null);
          setSelectedFileName("");
          setAsgname("")
          setUrl("");
          setisfilemodalOpen(false);
          api.open({
            message: "Uploaded Successfully!",
            icon: (
              <img src="../../../assets/icons/tick-arrow.svg" alt="tick-arrow" />
            ),
          });

        } else if (response.message === "User not authorized") {
          window.location.href =
            `${process.env.REACT_APP_URL}/authorize`;
        } else {
          api.open({
            message: response.message,
            icon: <IoCloseCircle className="false-icon" />,
          });
        }
        setDisable(false);
      }
    } else {
      if (url === '' || asgname === '') {
        api.open({
          message: "Both CSV URL and filename is required!",
          icon: <IoCloseCircle className="false-icon" />,
        });
        setDisable(false);
      } else {
        const response = await uploadGoogleSheet(url, asgname);
        if (response.success) {
          // setActiveButton("showCSV");
          if (listTable) {
            setSelected(asgname);
          }
          const result = await getColumns(asgname);
          if (result.success) {
            setColumns(result.data);
          }
          setSelectedFile(null);
          setAsgname("")
          setSelectedFileName("");
          setUrl("");
          setisfilemodalOpen(false);
          api.open({
            message: "Uploaded Successfully!",
            icon: (
              <img src="../../../assets/icons/tick-arrow.svg" alt="tick-arrow" />
            ),
          });
        } else {
          api.open({
            message: response.message,
            icon: <IoCloseCircle className="false-icon" />,
          });
        }
        setDisable(false);
      }
    }
  };

  const handleCancel = () => {
    setisfilemodalOpen(false);
    setSelectedFile(null);
    setSelectedFileName("");
    setTrackRecordCount(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFile = async (files) => {
    setSelectedFileName(files[0].name);
    setSelectedFile(files[0]);

    const response = await getImportedRecordsNumber(files[0]);
    if (response.success) {
      setTrackRecordCount(response.number_of_records);
    } else {
      setTrackRecordCount(0);
    }
  };

  const handleRemoveFile = (e) => {
    e.preventDefault();
    setSelectedFile(null);
    setSelectedFileName("");
    setTrackRecordCount(null);
    inputRef.current.value = "";
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    setSelectedFileName("");
    setSelectedFile(null)
    setTrackRecordCount(null)
    setActiveButton("importCSV");
  }, [isfilemodalOpen]);

  return (
    <>
      <Modal
        open={isfilemodalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="file-modal"
        centered={true}
      // width="37%"
      >
        <div className="file-modal-content">
          {/* Buttons Group */}
          <div className="file-modal-header h-center gap-4">
            <button
              className={activeButton === "googleSheets" ? "active" : ""}
              onClick={() => handleButtonClick("googleSheets")}
            >
              Google Sheets
            </button>

            <button
              className={activeButton === "importCSV" ? "active" : ""}
              onClick={() => handleButtonClick("importCSV")}
            >
              Import CSV
            </button>

            {listTable && (
              <button
                className={activeButton === "showCSV" ? "active" : ""}
                onClick={() => handleButtonClick("showCSV")}
              >
                CSV List
              </button>
            )}
          </div>

          {/* Tabs sections */}
          <div className="file-content">
            {activeButton === "googleSheets" && (
              <div className="google-sheets-content d-flex flex-column">
                <p>Spreadsheet</p>
                <input
                  className="url-input"
                  id="urlInput"
                  type="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Copy/paste spreadsheet URL"
                />

                <p>Assign Name</p>
                <input
                  className="url-input"
                  id="asgname"
                  type="text"
                  value={asgname}
                  onChange={(e) => setAsgname(e.target.value)}
                  placeholder="Assign name of file"
                />
              </div>
            )}

            {activeButton === "importCSV" && (
              <div className="import-csv-content v-center flex-column">
                <form
                  id="form-file-upload"
                  className="v-center flex-column"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  {selectedFile ? (
                    <BsFiletypeCsv className="file-icon" />
                  ) : (
                    <img src="../../assets/icons/upload.png" alt="" />
                  )}

                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    accept=".csv"
                    onChange={handleChange}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={
                      dragActive || selectedFileName ? "drag-active" : ""
                    }
                  >
                    {selectedFile && (
                      <MdDelete
                        className="delete-icon"
                        onClick={handleRemoveFile}
                      />
                    )}

                    <div>
                      {selectedFileName ? (
                        <p>{selectedFileName}</p>
                      ) : (
                        <p>Select a file or drag and drop here</p>
                      )}

                      {selectedFileName ? null : (
                        <button
                          className="button-purple-transparent px-3 py-3"
                          onClick={onButtonClick}
                        >
                          Import a CSV
                        </button>
                      )}
                    </div>
                  </label>

                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </div>
            )}

            {activeButton === "showCSV" && (
              <div className="list-contacts-csv">
                <Listofcontacttable
                  listTable={listTable}
                  setSelected={setSelected}
                  setisfilemodalOpen={setisfilemodalOpen}
                />
              </div>
            )}

            {activeButton !== "showCSV" && (
              <div className="email-modal-bottom v-center space-between gap-3">
                <p className="segoe-family">
                  {trackRecordCount &&
                    `${trackRecordCount} recipients in list`}
                </p>

                <div className="email-right-btns d-flex gap-3">
                  {/* <a href="../../assets/files/dummy.csv" download="dummy.csv">
                    <button className="button-purple-transparent px-3">
                      Dummy CSV
                    </button>
                  </a> */}
                  <a href=" https://docs.google.com/spreadsheets/d/10w6-j_fPbtIo_mB_TIXHclQSLFpNJRWOsFvNH9JEZmU/edit?usp=sharing" target="_blank" >
                    <button className="button-purple-transparent px-3">
                      Example CSV
                    </button>
                  </a>

                  <button
                    className="button-purple-transparent px-3"
                    onClick={handleCancel}
                  >
                    Close
                  </button>

                  <button className="button-purple-dark" onClick={handleOk} style={{
                    opacity: disable ? 0.6 : 1,
                    cursor: disable ? "not-allowed" : "pointer",
                  }}
                    disabled={disable}
                  >
                    {disable ? (
                      <div className="d-flex h-center v-center gap-2">
                        <div className="send-loading" />
                        <span>Uploading...</span>
                      </div>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {contextHolder}
    </>
  );
};

export default SelectFileModal;
