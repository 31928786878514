import React, { createContext, useReducer, useContext } from "react";
import { MdCheckCircle, MdSmsFailed, MdInfo, MdWarning } from "react-icons/md";
import readNotification from '../utils/notifications/readNotification';
import removeNotification from '../utils/notifications/removeNotification';
import clearAllNotifications from '../utils/notifications/clearAllNotifications';

const NotificationContext = createContext();

const initialState = [];

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return action.payload?.map(notification => ({
        ...notification,
        icon: getIcon(notification.status),
      }));

    case "ADD_NOTIFICATION":
      const { id, name, status, date_and_time, opened } = action.payload;
      const icon = getIcon(status);
      return [
        { id, name, status, date_and_time, icon, opened },
        ...state,
      ];

    case "REMOVE_NOTIFICATION":
      return state.filter((notification) => notification.id !== action.payload);

    case "UPDATE_NOTIFICATION_STATUS":
      return state.map((notification) =>
        notification.id === action.payload.id
          ? { ...notification, status: action.payload.status, opened: true }
          : notification
      );

    case "CLEAR_NOTIFICATIONS":
      return [];

    default:
      return state;
  }
};

const getIcon = (status) => {
  switch (status) {
    case "success":
      return <MdCheckCircle color="green" />;
    case "alert":
      return <MdSmsFailed color="red" />;
    case "info":
      return <MdInfo color="blue" />;
    case "warning":
      return <MdWarning color="orange" />;
    default:
      return <MdInfo color="gray" />;
  }
};

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const addNotification = (notification) => {
    dispatch({ type: 'ADD_NOTIFICATION', payload: notification });
  };

  const removeNewNotification = async (id) => {
    dispatch({ type: 'REMOVE_NOTIFICATION', payload: id });
    await removeNotification(id);
  };

  const readNewNotification = async (id) => {
    const notification = state.find(notif => notif.id === id);
    if (notification) {
      dispatch({ type: 'UPDATE_NOTIFICATION_STATUS', payload: { id, status: notification.status } });
      await readNotification(id);
    }
  };

  const removeAllNotifications = async () => {
    dispatch({ type: 'CLEAR_NOTIFICATIONS' });
    await clearAllNotifications();
  };

  const getNotificationsList = (notifications) => {
    dispatch({ type: "SET_NOTIFICATIONS", payload: notifications.reverse() });
  }

  return (
    <NotificationContext.Provider value={{
      notifications: state,
      addNotification,
      readNewNotification,
      removeNewNotification,
      removeAllNotifications,
      getNotificationsList,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);


