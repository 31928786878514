import moment from 'moment';

const googleAuth = async (data) => {
    const now = new Date();
    const formattedTime = moment(now).format('YYYY-MM-DD HH:mm:ss.SSSSSS');

    const reqData = {
        email: data.email,
        first_name: data.given_name,
        last_name: data.family_name ? data.family_name : "",
        avatar: data.picture,
        user_time: formattedTime
    };
    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/token-generator`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqData)
            }
        );

        if (response.ok) {
            const result = await response.json();
            sessionStorage.setItem("token", result.access_token);
            sessionStorage.setItem("first_name", data.given_name);
            sessionStorage.setItem("last_name", data.family_name !== undefined ? data.family_name : "");
            sessionStorage.setItem("email", data.email);
            sessionStorage.setItem("avatar", data.picture);
            return { success: true, result: result.access_token }
        } else {
            return { success: false, message: "Something went wrong!" }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }
    }
}

export default googleAuth;
