import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const CreditModal = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="credit-modal"
        centered={true}
        // width="28%"
      >
        <div className="modal-content">
          <div className="purple-header"></div>
          <div className="bottom-content v-center flex-column">
            <img
              src="../../assets/images/dashboardimages/creditImg.svg"
              alt="credits"
              className="w-auto h-auto"
            />
            <h4 className="elarge montserrat-family">More template credits</h4>
            <p className="p-med text-beige segoe-family text-center">
              Elevate your outreach with templates precisely generated for you!
            </p>
            <div className="modal-buttons d-flex gap-16 mt-24">
              <button
                className="text-darkpurple segoe-family"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  navigate("/buy-templates");
                  handleCancel();
                }}
              >
                Buy More
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CreditModal;
