import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./dashboard/dashboard";
import Dashboardoutlet from "./dashboard/dashboardoutlet";
import Dashboardmain from "./dashboard/dashboardmain/dashboardmain";
import CreateCampaign from "./dashboard/dashboardmain/campaign/createcampaign";
import Startcampaign from "./dashboard/dashboardmain/campaign/startcampaign";
import Templatemain from "./dashboard/templates/templatemain";
import Templatedetail from "./dashboard/templates/templatedetail";
import Listofcontacts from "./dashboard/listofcontacts/listofcontacts";
import Emailanalytics from "./dashboard/emailanalytics/emailanalytics";
import Settings from "./dashboard/settings/settingmain";
import Profile from "./dashboard/profile/Profile";
import Subscription from "./dashboard/subscription/subscription";
import OAuth from "./pages/OAuth";
import Protected from "./components/Protected";
import Templates from "./dashboard/templates/Templates";
import CampaignDetails from "./dashboard/dashboardmain/campaign-details/CampaignDetails";
import HowItWorks from "./dashboard/how-it-works/HowItWorks";
import NotFound from "./pages/NotFound";
import BuyTemplates from "./dashboard/buy-templates/BuyTemplates";
import { GlobalDebug } from "./utils/GlobalDebug";
import { useEffect } from "react";

const AppLayout = () => {
  useEffect(() => {
    GlobalDebug(false, true);
  }, []);

  return (
    <>
      <ToastContainer />
      <RouterProvider router={appRouter} />
    </>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <OAuth />,
  },
  {
    path: "/",
    element: <Protected Component={<Dashboard />} />,
    children: [
      {
        path: "dashboard",
        element: <Protected Component={<Dashboardoutlet />} />,
        children: [
          {
            path: "",
            element: <Protected Component={<Dashboardmain />} />,
          },
          {
            path: "campaign-details/:campaign_name",
            element: <Protected Component={<CampaignDetails />} />,
          },
          {
            path: "createcampaign",
            element: <Protected Component={<CreateCampaign />} />,
          },
          {
            path: "startcampaign",
            element: <Protected Component={<Startcampaign />} />,
          },
        ],
      },
      {
        path: "templates",
        element: <Protected Component={<Templates />} />,
        children: [
          {
            path: "",
            element: <Protected Component={<Templatemain />} />,
          },
          {
            path: "preview-template",
            element: <Protected Component={<Templatedetail />} />,
          },
        ],
      },
      {
        path: "list-of-contacts",
        element: <Protected Component={<Listofcontacts />} />,
      },
      {
        path: "email-analytics",
        element: <Protected Component={<Emailanalytics />} />,
      },
      {
        path: "subscription",
        element: <Protected Component={<Subscription />} />,
      },
      {
        path: "buy-templates",
        element: <Protected Component={<BuyTemplates />} />,
      },
      {
        path: "how-it-works",
        element: <Protected Component={<HowItWorks />} />,
      },
      {
        path: "settings",
        element: <Protected Component={<Settings />} />,
      },
      {
        path: "profile",
        element: <Protected Component={<Profile />} />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default AppLayout;
