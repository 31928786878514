import React from "react";
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "../utils/payment/StripePayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PurchaseModal = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckout = async (templates) => {
    const stripe = await stripePromise;

    const data = {
      plan_name: `${templates} Templates`,
    };

    const response = await StripePayment(data);

    if (response.success) {
      const result = await stripe.redirectToCheckout({
        sessionId: response.session,
      });

      if (result.error) {
        console.error(result.error.message);
      } else {
        navigate("/settings?tab=subscription");
      }
    }
  };

  const templatesPlan = [
    {
      id: 1,
      name: "Base",
      templates: 5,
      price: 3,
      button: "Purchase",
    },
    {
      id: 2,
      name: "Pro",
      templates: 15,
      price: 9,
      button: "Purchase",
    },
    {
      id: 3,
      name: "Premium",
      templates: 25,
      price: 15,
      button: "Purchase",
    },
  ];

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="purchase-modal"
        centered={true}
        width="60vw"
      >
        <div className="purchasemodal-content h-center gap-3">
          {templatesPlan.map((item) => (
            <div
              key={item.id}
              className={`purchase-card ${
                item.id === 2 ? "enterprise-card text-white" : "text-darkpurple"
              }`}
            >
              {/* <h4>{item.name}</h4> */}
              <span className="v-center gap-2  segoe-family">
                <Icon
                  color={item.id === 2 ? "#fff" : "#28104E"}
                  icon="entypo:check"
                  width={16}
                  height={16}
                  className="mt-2"
                />
                <span className="d-flex gap-2">
                  <h2 className="small">{item.templates}</h2>
                  <h5 className="mb-2">Templates</h5>
                </span>
              </span>
              <div className="amt-txt v-center">
                <span className="d-flex ">
                  <label className="large">$</label>
                  <h4>{item.price}</h4>
                </span>
                {/* <label className="large">/month</label> */}
              </div>
              
              <button
                className="text-darkpurple"
                onClick={() => handleCheckout(item.templates)}
              >
                Purchase
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default PurchaseModal;
