const removeTemplate = async (name) => {
    const tokens = sessionStorage.getItem("token");
    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/remove-template?template_name=${name}`,
            {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokens}`
                },
            }
        );

        const result = await response.json();
        if (response.ok) {
            return { success: true, message: result.message }
        } else {
            return { success: false, message: result.message }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }

    }
}

export default removeTemplate