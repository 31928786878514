import React, { useEffect, useState } from "react";
import ProfileComponent from "../../components/profilecomponent";
import { Skeleton } from "antd";
import { useCompaign } from "../../context/CompaignProvider";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const { profile } = useCompaign();

  useEffect(() => {
    if (profile.firstname) {
      setLoading(false);
    }
  }, [profile]);

  return (
    <div className="profile-main d-flex flex-column">
      <h5 className="large">Profile</h5>
      {loading ? (
        <div className="profile-main-container d-flex gap-3">
          <div className="profile-left-container d-flex flex-column">
            <div className="profile-dp-container profile-bg-radius">
              <Skeleton.Avatar size={150} shape="circle" active />
              <Skeleton paragraph={{ rows: 2 }} active />
            </div>

            <div className="account-setting-container profile-bg-radius">
              <Skeleton paragraph={{ rows: 3 }} active />
            </div>
          </div>

          <div className="profile-right-container d-flex flex-column">
            <div className="company-info-container profile-bg-radius">
              <Skeleton paragraph={{ rows: 5 }} active />
            </div>

            <div className="profile-bottom-container profile-bg-radius d-flex space-between">
              <Skeleton.Button active />
              <Skeleton.Button active />
            </div>
          </div>
        </div>
      ) : (
        <ProfileComponent />
      )}
    </div>
  );
};

export default Profile;
