import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import addtemplate from "../../utils/templates/addTemplate";
import { notification } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import { useCompaign } from "../../context/CompaignProvider";
import { usePlans } from "../../context/PlansProvider";
import planInfo from "../../utils/planInfo";

const Templatedetail = () => {
  const navigate = useNavigate();
  const contentRefs = useRef({});
  const containerRef = useRef(null);
  const { setActivePlan } = usePlans();
  const [isOverflowing, setIsOverflowing] = useState({});
  const {
    templates,
    setCompaignMail,
    setSubject,
    setSelectedFile,
    setColumns,
  } = useCompaign();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileTemplate, setSelectedFileTemplate] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const review = queryParams.get("review");
  const selectedNames = queryParams.get("selected");
  const selectedTemplates = selectedNames ? selectedNames.split(",") : [];
  const sessionTemplate = JSON.parse(sessionStorage.getItem("template"));
  const [api, contextHolder] = notification.useNotification();
  const [expandedItems, setExpandedItems] = useState([]);

  const handleFile = async (files) => {
    setSelectedFileName(files[0].name);
    setSelectedFileTemplate(files[0]);
  };

  const handleRemoveFile = (e) => {
    e.preventDefault();
    setSelectedFileTemplate(null);
    setSelectedFileName("");
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const convertToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-") // Replace spaces with hyphens
      .replace(/[^\w-]+/g, ""); // Remove non-word characters except hyphens
  };

  const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const handleSubmit = async () => {
    let fileToUpload = selectedFileTemplate;
    const defaultImagePath = "../../assets/images/dashboardimages/emailthumbnail.png";

    if (!selectedFileTemplate) {
      fileToUpload = await urlToFile(
        defaultImagePath,
        "emailthumbnail.png",
        "image/png"
      );
    }

    const data = {
      template_name: title,
      template_body: sessionTemplate[0].template_body,
      file: fileToUpload,
    };
    if (title === "") {
      api.open({
        message: "Template name is required",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (sessionTemplate === "") {
      api.open({
        message: "Template body is required",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else {
      const result = await addtemplate(data);
      if (result.success) {
        api.open({
          message: result.message,
          icon: (
            <img src="../../../assets/icons/tick-arrow.svg" alt="tick-arrow" />
          ),
          onClose: () => navigate("/templates"),
        });

        const response = await planInfo();
        if (response.success) {
          setActivePlan(response.data);
        }
      } else {
        api.open({
          message: result.message,
          icon: <IoCloseCircle className="false-icon" />,
        });
      }
    }
  };

  const isTemplateSelected = (templateName) => {
    // const slug = convertToSlug(templateName);
    return selectedTemplates.includes(templateName);
  };

  const hasTemplateSelected = (templateName) => {
    return expandedItems.includes(templateName);
  };

  const toggleItem = (templateName) => {
    if (expandedItems.includes(templateName)) {
      setExpandedItems(expandedItems.filter((item) => item !== templateName));
    } else {
      setExpandedItems([...expandedItems, templateName]);
    }
  };

  const handlePreviews = () => {
    let templateArray = [];
    templates.forEach((item) => {
      const isUsingTemplate = isTemplateSelected(item.template_name);
      if (isUsingTemplate) {
        templateArray.push({
          template_name: item.template_name,
          template_body: item.template_body,
        });
      }
    });
    sessionStorage.setItem("template", JSON.stringify(templateArray));
    setCompaignMail(templateArray);
    navigate("/dashboard/startcampaign");
  };

  useEffect(() => {
    const checkOverflow = () => {
      const newIsOverflowing = {};
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        Object.keys(contentRefs.current).forEach((templateName) => {
          const contentEl = contentRefs.current[templateName];
          if (contentEl) {
            newIsOverflowing[templateName] = contentEl.scrollHeight > containerHeight;
          }
        });
      }
      setIsOverflowing(newIsOverflowing);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [templates]);

  useEffect(() => {
    if (!review) {
      sessionStorage.removeItem("template");
      sessionStorage.removeItem("subject");
      setCompaignMail([""]);
      setSubject("");
      setSelectedFile("");
      setColumns([]);
      sessionStorage.removeItem("textColor");
      sessionStorage.removeItem("bgColor");
      sessionStorage.removeItem("linkColor");
    }
  }, [review, setColumns, setCompaignMail, setSelectedFile, setSubject]);

  return (
    <>
      <div className={`template-detail-main-container d-flex space-between`} ref={containerRef}>
        {review ? (
          <div className={`temp-detail-left-main overflow-auto w-75`}>
            {/* Template Title */}
            <>
              {title === "" ? (
                <h4 className="text-dim">ADD TITLE HERE</h4>
              ) : (
                <h4 className="elarge">{title}</h4>
              )}
            </>

            {/* Template Body */}
            <div className="temp-detail-inner-content d-flex flex-column gap-4">
              <p
                dangerouslySetInnerHTML={{
                  __html: sessionTemplate[0].template_body,
                }}
              />
            </div>

            {/* Save Button */}
            <button
              className="button-purple-transparent px-4 py-2"
              onClick={handleSubmit}
            >
              Save Template
            </button>
          </div>
        ) : (
          <div className={`preview-emails-container`}>
            <div className="email-grid">
              {templates.map((item, index) => {
                return isTemplateSelected(item.template_name) ? (
                  // overflow-auto
                  <div
                    key={index}
                    className={`temp-detail-left-main ${isOverflowing[item.template_name] &&
                      !hasTemplateSelected(item.template_name)
                      ? "overflow-hidden"
                      : "overflow-auto"
                      } w-full`}
                  >
                    <h4 className="elarge">{item.template_name}</h4>

                    <div
                      ref={(el) => (contentRefs.current[item.template_name] = el)}
                      className="temp-detail-inner-content d-flex flex-column gap-4"

                    >
                      <p
                        dangerouslySetInnerHTML={{ __html: item.template_body }}
                      />
                    </div>

                    {isOverflowing[item.template_name] &&
                      !hasTemplateSelected(item.template_name) && (
                        <button
                          className="button-purple-transparent px-4 py-2"
                          onClick={() => toggleItem(item.template_name)}
                        >
                          View more
                        </button>
                      )}
                  </div>
                ) : null;
              })}
            </div>

            <div className="use-template-btn-container">
              <button
                className="button-purple-transparent px-4 py-2"
                onClick={handlePreviews}
              >
                Use this Template
              </button>
            </div>
          </div>
        )}

        {/* Sidebar */}
        {review && (
          <div className="temp-detail-right-main">
            <span className=" d-flex flex-column gap-8 w-100">
              <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                Template title
              </p>
              <input
                type="text"
                placeholder="Product Announcement Campaign"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="profile-input"
              />
            </span>

            <p className="body-med text-darkpurple plus-jakarta-font mt-4 mx-2">
              Template Thumbnail
            </p>

            <div className="import-img v-center flex-column">
              <form
                id="form-file-upload"
                className="v-center flex-column"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                {selectedFileTemplate ? (
                  <img
                    className="upload-img"
                    src={URL.createObjectURL(selectedFileTemplate)}
                    alt={selectedFileTemplate}
                  />
                ) : (
                  <div onClick={onButtonClick} className="upload-container">
                    <img
                      src="../../assets/icons/upload.png"
                      className="cursor-pointer upload-icon"
                      alt=""
                    />
                    {!selectedFileName && <p>Select thumbnail here!</p>}
                  </div>
                )}

                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  accept="image/png, image/jpeg, image/gif, image/bmp"
                  onChange={handleChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={
                    dragActive || selectedFileName ? "drag-active" : ""
                  }
                >
                  {selectedFileTemplate && (
                    <MdDelete
                      className="delete-icon"
                      onClick={handleRemoveFile}
                    />
                  )}
                </label>

                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
          </div>
        )}

        {contextHolder}
      </div>
    </>
  );
};

export default Templatedetail;
