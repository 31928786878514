import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CreditModal from "../modals/creditModal";
import { Skeleton, Dropdown, Menu, Button, Tooltip } from "antd";
import { useCompaign } from "../context/CompaignProvider";
import { useNotifications } from "../context/NotificationProvider";
import { formatTimeAgo } from "../utils/formatTimeAgo";
import { usePlans } from "../context/PlansProvider";
import { HiBellAlert } from "react-icons/hi2";
import { RiDeleteBin6Line, RiNotification4Fill } from "react-icons/ri";
import { FaBell } from "react-icons/fa";

const Dashnavbar = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const { activePlan } = usePlans();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { profile } = useCompaign();
  const {
    notifications,
    readNewNotification,
    removeNewNotification,
    removeAllNotifications,
  } = useNotifications();

  useEffect(() => {
    if (profile.firstname) {
      setLoading(false);
    }
  }, [profile]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const notificationMenu = (
    <Menu style={{ maxWidth: 350 }}>
      {notifications?.length > 0 ? (
        <>
          <div className="clear-all-container">
            <RiNotification4Fill className="icon" style={{ opacity: 0.3, cursor: 'auto' }} />

            <Button onClick={removeAllNotifications} type="link">
              Clear All
            </Button>
          </div>

          {notifications.map((notification) => (
            <Menu.Item
              key={notification.id}
              onClick={() => readNewNotification(notification.id)}
            >
              <div className="notification-item">
                <div
                  className="notification-icon"
                  style={{ opacity: notification.opened ? 0.5 : 1 }}
                >
                  {notification.icon}
                </div>
                <div
                  className="notification-content"
                  style={{ opacity: notification.opened ? 0.6 : 1 }}
                >
                  <div>{notification.name}</div>
                  <div className="notification-time">
                    {formatTimeAgo(notification.date_time)}
                  </div>
                </div>
                <RiDeleteBin6Line
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeNewNotification(notification.id);
                  }}
                />
              </div>
            </Menu.Item>
          ))}
        </>
      ) : (
        <Menu.Item disabled>
          <div className="notification-item">
            <div className="notification-contet">
              <HiBellAlert className="notification-bell" />
              <div>No notification in bucket</div>
            </div>
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <div className="header-bg" />
      <div className="dashnavbar-container fixed v-center">
        <div className="dashnav-left d-none v-center gap-16">
          <Tooltip title="Menu">
            <Icon icon="ion:menu" width={20} height={20} onClick={toggleDrawer} />
          </Tooltip>
        </div>
        
        <div className="dashnav-right v-center gap-4">
          <div className="trophy-div d-flex gap-2">
            <Tooltip title="Remaining templates">
              <span className="body-large text-center">
                {activePlan.total_saved_templates || 0}/
                {((activePlan.additional_template_count) || 0) + ((activePlan.plan_template_count) || 0)}
              </span>
            </Tooltip>
            
            <Tooltip title="Buy templates">
              <img
                src="../../assets/icons/trophy.svg"
                alt="notification"
                className="trophy-icon cursor-pointer"
                onClick={showModal}
              />
            </Tooltip>
          </div>
          
          <div className="notification-section">
            <Tooltip title="Notification">
              <Dropdown overlay={notificationMenu} trigger={["click"]}>
                <FaBell size={24} opacity={0.5} />
              </Dropdown>
            </Tooltip>
            {notifications && notifications.filter((item) => !item.opened).length > 0 && (
              <span>{notifications.filter((item) => !item.opened).length}</span>
            )}
          </div>
          <Tooltip title="Profile">
            <div className="nav-user-profile v-center gap-16">
              {!loading ? (
                <div className="img-wrapper">
                  <img
                    src={profile.avatar}
                    alt="avatar"
                    className="cursor-pointer user-dp-img"
                    onClick={() => navigate("/profile")}
                  />
                </div>
              ) : (
                <Skeleton.Avatar size={40} shape="circle" active />
              )}
              <span
                className="p-small cursor-pointer text-beige"
                onClick={() => navigate("/profile")}
              >
                {loading ? (
                  <div className="flex-vertical">
                    <Skeleton.Input size={13} />
                    <Skeleton.Input size={8} />
                  </div>
                ) : (
                  <>
                    <h5>
                      {profile.firstname}{" "}
                      {profile.lastname !== "undefined" ? profile.lastname : ""}
                    </h5>
                    {profile.email}
                  </>
                )}
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
      
      <CreditModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Dashnavbar;
