const uploadListOfContacts = async (file,filename) => {
    const tokens = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/upload-file?filename=${filename}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${tokens}`
            },
            body: formData
        });
        
        const result = await response.json();
        if (response.ok) {
            return { success: true, message: result };
        } else {
            return { success: false, message: result.detail };
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` };
    }
};

export default uploadListOfContacts;
