const addtemplate = async (data) => {
    const tokens = sessionStorage.getItem("token")

    try {
        const formData = new FormData();
        formData.append('template_name', data.template_name);
        formData.append('template_body', data.template_body);
        formData.append('slug', data.template_body);
        formData.append('file', data.file);

        const response = await fetch(`${process.env.REACT_APP_URL}/upload-template`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${tokens}`
            },
            body: formData
        });

        const result = await response.json();
        if (response.ok) {
            return { success: true, message: result.detail }
        } else {
            return { success: false, message: result.detail }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }
    }
}

export default addtemplate;
