import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { useCompaign } from "../../context/CompaignProvider";

const CampaignTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const { campaigndata, setCampaignData } = useCompaign();

  useEffect(() => {
    const data = sessionStorage.getItem("compaignList");
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        if (Array.isArray(parsedData)) {
          setCampaignData(parsedData);
        } else {
          console.error("Parsed data is not an array");
          setCampaignData([]);
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
        setCampaignData([]);
      }
    } else {
      setCampaignData([]);
    }
  }, [setCampaignData]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = campaigndata.slice(startIndex, endIndex);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedTimeUpperCase = formattedTime.replace(/am|pm/i, (match) => match.toUpperCase());

    return `${formattedDate} at ${formattedTimeUpperCase}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="camp-main-table">
        <table className="m-auto camp-table">
          <thead>
            <tr className="camp-email-thead">
              <th>
                <div className="email-check-div v-center gap-4">Campaign Name</div>
              </th>
              <th>Template Name</th>
              <th>Total Contacts</th>
              <th>Clicks</th>
              <th>Date of sending</th>
            </tr>
          </thead>

          <tbody>
            {visibleData && visibleData.map((data, index) => (
              <tr key={index} className="camp-email-tbody">
                <td>{data.campaign_name.length > 30 ? `${data.campaign_name.slice(0, 30)}...` : data.campaign_name}</td>
                <td>
                  {data.template_data
                    .map((td) => td.template_name)
                    .join(", ").length > 30
                    ? `${data.template_data.map((td) => td.template_name).join(", ").slice(0, 30)}...`
                    : data.template_data.map((td) => td.template_name).join(", ")}
                </td>
                <td>{data["Total Contacts"]}</td>
                <td>{data["Total Count"]}</td>
                <td>{formatDate(data["Sending Date"])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {(!visibleData || visibleData.length < 1) && (
        <div className="text-center text-black montserrat-family w-full p-4">
          No campaigns to show here
        </div>
      )}

      <div className="contacts-bottom-div d-flex space-between align-items-center gap-2 pt-4">
        <label className="small">{`Showing ${campaigndata.length === 0 ? startIndex : startIndex + 1} to ${endIndex > campaigndata.length ? campaigndata.length : endIndex} of ${campaigndata.length} results`}</label>

        <Pagination
          defaultCurrent={1}
          total={campaigndata.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default CampaignTable;
