const dynamicMailCompaign = async (data) => {
  const tokens = sessionStorage.getItem("token");
  // const email = sessionStorage.getItem("email");
  const sid = sessionStorage.getItem("sid");

  const template = {
    template: data.templates_data,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/v2/replace-placeholders?filename=${data.file}&socket_id=${sid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
        body: JSON.stringify(template),
      }
    );
    const result = await response.json();

    if (response.ok) {
      return { success: true, message: result.message, task_id: result.task_id };
    } else {
      return { success: false, message: result.detail };
    }
  } catch (e) {
    return { success: false, message: `Something went wrong: ${e}` };
  }
};

export default dynamicMailCompaign;
