import React, { useState } from "react";
import { Checkbox, Modal } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Emailtemplate from "../components/emailtemplate";
import { notification } from "antd";
import "react-toastify/dist/ReactToastify.css";
import SendEmailModal from "./sendEmailModal";
import sendEmails from "./../utils/sendEmails";
import { IoCloseCircle } from "react-icons/io5";
import fetchContactsByName from "../utils/fetchContactsByName";
import moment from "moment";
import { useCompaign } from "../context/CompaignProvider";

const Previewcampaignmodal = ({
  isModalOpen,
  setIsModalOpen,
  sender,
  loader,
  selectedFile,
  editorContent,
  textColor,
  linkColor,
  bgColor,
  setEditorContent,
  subject,
  setSubject,
  fontFamily,
  scheduleDate,
  scheduleTime,
  recordLength
}) => {
  const [issendModalOpen, setissendModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disableBulk, setDisableBulk] = useState(false);
  const [filterEmails, setFilterEmails] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { compaignMail } = useCompaign();

  const showbulkEmailModal = async () => {
    setDisableBulk(true);
    const response = await fetchContactsByName(selectedFile);
    if (response.success) {
      setFilterEmails(
        response.data.map((item, index) => ({
          id: index + 1,
          email: item.Email || item.email,
          name: item.Name || item.name,
        }))
      );
      setDisableBulk(false);
      setIsEmailModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlesendOk = () => {
    setissendModalOpen(false);
  };

  const handlesendCancel = () => {
    setissendModalOpen(false);
  };

  const showsendModal = () => {
    setissendModalOpen(true);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // swipe: false
  };

  function toLocalISOString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const handleSend = async () => {
    setDisable(true);
    const now = new Date();
    const formattedDate = toLocalISOString(now);
    const minAllowedDateTime = new Date(now.getTime() + 15 * 60000);
    const minAllowedDateString = moment(minAllowedDateTime).format("YYYY-MM-DD");
    const minAllowedTimeString = moment(minAllowedDateTime).format("HH:mm");
    const date = scheduleDate ? moment(scheduleDate.$d).format("YYYY-MM-DD") : null;
    const time = scheduleTime;
    let datetime = null;

    if (date && time) {
      datetime = `${date}T${time}`;
      const scheduledDateTime = new Date(datetime);

      const scheduledDateString = moment(scheduledDateTime).format("YYYY-MM-DD");
      const scheduledTimeString = moment(scheduledDateTime).format("HH:mm");

      const isTooEarly = scheduledDateString < minAllowedDateString ||
        (scheduledDateString === minAllowedDateString && scheduledTimeString < minAllowedTimeString);

      if (isTooEarly) {
        api.open({
          message: `Scheduled time must be at least 15 minutes forward from now!`,
          icon: <IoCloseCircle className="false-icon" />,
        });
        setDisable(false);
        setissendModalOpen(false);
        return;
      }
    } else {
      datetime = formattedDate;
    }

    const data = editorContent.map((item) => ({
      email: item.email,
      body: `<div style="background-color: ${bgColor}; font-family:${fontFamily};">${item.response_text}</div>`,
      subject: subject,
      template_name: item.template_name,
    }));

    const templates_data = compaignMail.map((item) => ({
      ...item,
      template_name: item.template_name === "" ? subject : item.template_name,
    }));

    const campaign_name = subject;

    try {
      const result = await sendEmails(
        data,
        datetime,
        formattedDate,
        campaign_name,
        templates_data,
        selectedFile
      );

      if (result.success) {
        setIsModalOpen(false);
        api.open({
          message: result.message,
          icon: <img src="../../assets/icons/tick-arrow.svg" alt="tick-arrow" />,
        });
        setDisable(false);
        setissendModalOpen(false);
      } else if (result.message === "User not authorized") {
        window.location.href = `${process.env.REACT_APP_URL}/authorize`;
        setDisable(false);
        setissendModalOpen(false);
      } else {
        api.open({
          message: result.message,
          icon: <IoCloseCircle className="false-icon" />,
        });
        setDisable(false);
        setissendModalOpen(false);
      }
    } catch (error) {
      api.open({
        message: `An error occurred: ${error.message}`,
        icon: <IoCloseCircle className="false-icon" />,
      });
      setDisable(false);
      setissendModalOpen(false);

    };
  };

  const handleChecked = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="tempalte-modal overflow-x-hidden"
        centered={true}
        width={"70vw"}
        maskClosable={false} // Prevents closing when clicking outside
      >
        <h4 className="elarge d-flex v-center gap-2">
          {editorContent.length < recordLength && (<div className="campaign-loading" />)}
          Preview campaign ({editorContent.length || 0})
        </h4>

        <div className="preview-modal-content d-flex flex-column">
          <div className="d-flex justify-content-end">
            {!loader && editorContent.length === recordLength && (
              <button
                className="button-purple-dark py-2 px-3"
                onClick={showsendModal}

              >
                Send Email
              </button>
            )}
          </div>

          <div className="preview-slider m-auto">
            {loader ? (
              <Emailtemplate
                sender={sender}
                previewMode={true}
                loader={loader}
                editorContent={setEditorContent}
                setEditorContent={setEditorContent}
                subject={subject}
                setSubject={setSubject}
                modalclass
              />
            ) : (
              <Slider {...settings}>
                {editorContent.map((item, index) => (
                  <div key={index} className="slider-inner">
                    <Emailtemplate
                      sender={sender}
                      previewMode={true}
                      bgColor={bgColor}
                      textColor={textColor}
                      linkColor={linkColor}
                      editorContent={item}
                      setEditorContent={setEditorContent}
                      subject={subject}
                      fontFamily={fontFamily}
                      setSubject={setSubject}
                      modalclass
                    />
                  </div>
                ))}
                {/* <div className="slider-inner">
                <Emailtemplate previewMode={true} hide="d-none" />
              </div>
              <div className="slider-inner">
                <Emailtemplate previewMode={true} hide="d-none" />
              </div> */}
              </Slider>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        open={issendModalOpen}
        onOk={handlesendOk}
        onCancel={handlesendCancel}
        className="tempalte-send-modal overflow-x-hidden"
        centered={true}
        width={"24vw"}
      >
        <div className="send-modal">
          <div className="preview-send-modal-content h-center v-center gap-4">
            {contextHolder}
            <button
              className="button-purple-dark d-flex v-center h-center"
              onClick={handleSend}
              style={{
                opacity: disable || !isChecked ? 0.6 : 1,
                cursor: disable || !isChecked ? "not-allowed" : "pointer",
              }}
              disabled={disable || !isChecked}
            >
              {disable ? (
                <div className="d-flex h-center v-center gap-2">
                  <div className="send-loading" />
                  <span>Sending...</span>
                </div>
              ) : (
                "Send Email"
              )}
            </button>

            <button
              className="button-purple-dark d-flex v-center h-center"
              onClick={showbulkEmailModal}
              style={{
                opacity: disable || !isChecked ? 0.6 : 1,
                cursor: disable || !isChecked ? "not-allowed" : "pointer",
              }}
              disabled={disable || !isChecked}
            >
              {disableBulk ? (
                <div className="d-flex h-center v-center gap-2">
                  <div className="send-loading" />
                </div>
              ) : (
                "Bulk Email"
              )}
            </button>
          </div>

          <div className="concense">
            <Checkbox onChange={handleChecked}  />
            <span>I confirm that all recipients have consented to receive communications.</span>
          </div>

        </div>
      </Modal>

      <SendEmailModal
        isEmailModalOpen={isEmailModalOpen}
        setIsModalOpen={setIsModalOpen}
        setIsEmailModalOpen={setIsEmailModalOpen}
        filterEmails={filterEmails}
        bgColor={bgColor}
        fontFamily={fontFamily}
        subject={subject}
        selectedFile={selectedFile}
        scheduleDate={scheduleDate}
        scheduleTime={scheduleTime}
        editorContent={editorContent}
        // addCompaignListItem={addCompaignListItem}
        handleCloseParentModal={() => setissendModalOpen(false)}
      />
    </>
  );
};

export default Previewcampaignmodal;
