const getTemplates = async () => {
    const tokens = sessionStorage.getItem("token")

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/get-templates`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${tokens}`
            },
        });

        const result = await response.json();
        if (response.ok) {
            return { success: true, data: result }
        } else {
            return { success: false, message: result.detail }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }
    }
}

export default getTemplates;
