import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  DatePicker,
  TimePicker,
  notification,
  ColorPicker,
  Modal,
  Tooltip,
  Select,
} from "antd";
import Emailtemplate from "../../../components/emailtemplate";
import Previewcampaignmodal from "../../../modals/previewcampaignmodal";
import { useNavigate } from "react-router-dom";
import dynamicMailCompaign from "../../../utils/dynamicMailCompaign";
import { IoCloseCircle, IoEye } from "react-icons/io5";
import { useCompaign } from "../../../context/CompaignProvider";
import updateCsvFile from "../../../utils/contacts/update-datetime-csv";
import { FaAngleDown } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fetchContactsByName from "../../../utils/fetchContactsByName";
import Spreadsheet from "react-spreadsheet";
import { MdModeEditOutline } from "react-icons/md";
import { IoArrowBackOutline } from "react-icons/io5";
import { usePlans } from "../../../context/PlansProvider";
import csvFileEdit from "../../../utils/csvFileEdit";
import cancelSocket from "../../../utils/cancelSocket";

const Startcampaign = () => {
  const format = "HH:mm";
  const { activePlan } = usePlans();
  const navigate = useNavigate();
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [wordCount, setWordCount] = useState([]);
  const [fontFamily, setFontFamily] = useState("Segoe UI");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sender, setSender] = useState(sessionStorage.getItem("email"));
  const [bgColor, setBgColor] = useState(() => getInitialState("bgColor", "#ffffff"));
  const {
    editorContent,
    setEditorContent,
    compaignMail,
    setCompaignMail,
    subject,
    setSubject,
    colError,
    setColError,
    selectedFile,
    setSelectedFile,
    previewinstance,
    setSid
  } = useCompaign();
  const [api, contextHolder] = notification.useNotification();
  const [csvData, setCsvData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [grid, setGrid] = useState({ rows: [], columns: [] });
  const [recordLength, setRecordLength] = useState(0);
  const [genStatus, setGenStatus] = useState(false);
  const [taskId, setTaskId] = useState("");

  function getInitialState(key, defaultValue) {
    const saved = sessionStorage.getItem(key);
    return saved !== null ? JSON.parse(saved) : defaultValue;
  }

  const showModal = async () => {
    const subjectLength = subject.split(" ");
    setColError("");
    const isEmptyCampaign = compaignMail?.some(
      (item) =>
        !item.template_body ||
        item.template_body === "<p><br></p>" ||
        item.template_body === ""
    );

    if (selectedFile === "") {
      api.open({
        message: "Recepients are required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (!compaignMail || isEmptyCampaign) {
      api.open({
        message: "Mail body must be filled!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (
      (scheduleDate !== null && scheduleTime === null) ||
      (scheduleDate === null && scheduleTime !== null)
    ) {
      api.open({
        message: "Both date and time are required for scheduling!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (subject === "") {
      api.open({
        message: "Subject is required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (subjectLength.length > 30) {
      api.open({
        message: "Subject must be less than 30 words!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else {
      if (!activePlan.subscription_name) {
        api.open({
          message: "Subscribe to a plan first!",
          icon: <IoCloseCircle className="false-icon" />,
        });
      } else {
        const templates_data = compaignMail.map((item) => {
          return {
            ...item,
            template_name:
              item.template_name === "" ? subject : item.template_name,
          };
        });

        const campaignData = {
          templates_data: templates_data,
          file: selectedFile,
        };

        if (editorContent.length < 1) {
          setLoader(true);
          setIsModalOpen(true);
          setGenStatus(true);
          const response = await dynamicMailCompaign(campaignData);
          if (response.success) {
            setTaskId(response.task_id)
            await updateCsvFile(selectedFile);
          } else if (response.message === "User not authorized") {
            window.location.href = `${process.env.REACT_APP_URL}/authorize`;
            setLoader(false);
          } else {
            api.open({
              message: response.message,
              icon: <IoCloseCircle className="false-icon" />,
            });
            setLoader(false);
          }
        } else {
          setLoader(false);
          setIsModalOpen(true);
        }
      }
    }
  };

  const handleGenerateAndPreview = () => {
    if (
      wordCount.some((item) => item > activePlan.words_per_email)
    ) {
      api.open({
        message: "Words limited exceeded!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else {
      showModal();
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  function generateKey() {
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characters = "0123456789";

    let key = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters[randomIndex];
    }
    return key;
  }

  const showPreview = async () => {
    if (selectedFile !== "") {
      setIsModalVisible(true);
    } else {
      api.open({
        message: "Select CSV file first!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    }
  };

  function numberToColumnLetter(number) {
    let letter = "";
    while (number > 0) {
      const remainder = (number - 1) % 26;
      letter = String.fromCharCode(65 + remainder) + letter;
      number = Math.floor((number - 1) / 26);
    }
    return letter;
  }

  const disabledDate = (current) => {
    const now = moment();
    const sevenDaysFromNow = moment().add(30, "days");
    return (
      current < now.startOf("day") || current > sevenDaysFromNow.endOf("day")
    );
  };

  // Function to disable times outside valid range
  const disabledTime = () => {
    if (!scheduleDate) {
      return {};
    }
    const isToday = moment().isSame(scheduleDate.$d, "day");
    if (isToday) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      return {
        disabledHours: () => [...Array(currentHour).keys()],
        disabledMinutes: (hour) =>
          hour === currentHour ? [...Array(currentMinute).keys()] : [],
      };
    }

    return {};
  };

  const handleFileEdit = async () => {
    const response = await csvFileEdit(selectedFile);

    if (response.success) {
      window.open(response.link, "_blank");
    }
  };

  const handleCancel = async () => {
    if (previewinstance && previewinstance.readyState === WebSocket.OPEN) {
      await new Promise((resolve) => {
        previewinstance.onclose = () => {
          console.log("Previous WebSocket connection closed.");
          resolve();
        };
        previewinstance.close();
      });
    }

    const alphaNumericKey = generateKey();
    sessionStorage.setItem("sid", alphaNumericKey);
    setSid(alphaNumericKey);
    setGenStatus(false);
    const response = await cancelSocket(taskId);
    if (response.success) {
      setEditorContent([]);
    }
  };

  useEffect(() => {
    const csvRecords = async () => {
      if (selectedFile !== "") {
        const response = await fetchContactsByName(selectedFile);
        if (response.success) {
          setRecordLength(response.data.length);
          // Rows count
          const additionalRows = 4;
          const rowCount = Math.max(response.data.length, 1) + additionalRows;

          // Column count
          const maxKeys = Math.max(
            ...response.data.map((obj) => Object.keys(obj).length)
          );
          const additionalColumns = 1;
          const totalColumns = maxKeys + additionalColumns;

          setGrid((prev) => ({
            ...prev,
            rows: Array.from({ length: rowCount }, (_, i) => (i + 1).toString()),
            columns: Array.from({ length: totalColumns }, (_, i) =>
              numberToColumnLetter(i + 1)
            ),
          }));

          const keys = response.data.length ? Object.keys(response.data[0]) : [];
          const headers = keys.map((key) => ({ value: key, readOnly: true }));

          const gridData = Array.from({ length: rowCount }, (_, rowIndex) =>
            Array.from({ length: totalColumns }, (_, colIndex) => {
              const value =
                rowIndex === 0
                  ? headers[colIndex]?.value || ""
                  : response.data[rowIndex - 1]?.[keys[colIndex]] || "";
              return { value, readOnly: true };
            })
          );

          setCsvData(gridData);
        }
      }
    };

    csvRecords();
  }, [selectedFile]);

  useEffect(() => {
    sessionStorage.setItem("bgColor", JSON.stringify(bgColor));
  }, [bgColor]);

  useEffect(() => {
    const handleContent = async () => {
      if (genStatus) {
        if (editorContent.length > 1) {
          setLoader(false);
        } else if (editorContent.length > 0) {
          setIsModalOpen(true);
          setLoader(false);
        }
        // if (recordLength === editorContent.length) {
        //   await cancelSocket(taskId);
        // }
      }
    }

    handleContent();
  }, [editorContent]);

  useEffect(() => {
    if (compaignMail.length === 0) {
      const templateArray = JSON.parse(sessionStorage.getItem("template"));
      if (templateArray) {
        setCompaignMail(templateArray);
      }
    }
  }, [compaignMail.length, setCompaignMail]);

  // useEffect(() => {
  //   setEditorContent([]);
  //   setCsvData([]);
  // }, [selectedFile, compaignMail, subject, setEditorContent]);

  useEffect(() => {
    sessionStorage.setItem("template", JSON.stringify(compaignMail));
  }, [compaignMail]);

  useEffect(() => {
    const handleError = async () => {
      if (colError !== "") {
        setIsModalOpen(false);
        api.open({
          message: colError,
          icon: <IoCloseCircle className="false-icon" />,
        });

        const response = await cancelSocket(taskId);
        if (response.success) {
          setEditorContent([]);
          setGenStatus(false)
        }
      }
    }
    handleError();
  }, [colError, api]);

  return (
    <>
      <div className="start-campaign-main-conatiner align-center d-flex space-between">
        {/* Mail Body */}
        <div className="start-left-section d-flex flex-column">
          <div className="start-left-top v-center gap-2">
            <IoArrowBackOutline
              className="back-arrow"
              onClick={() => navigate("/dashboard/createcampaign")}
            />
            <h4 className="elarge">New Campaign</h4>
          </div>

          {compaignMail.length < 2 ? (
            <div>
              <Emailtemplate
                hideinput="d-none"
                selected={selectedFile}
                setSender={setSender}
                sender={sender}
                setSelected={setSelectedFile}
                emailBody={compaignMail[0]?.template_body}
                index={0}
                setEmailBody={setCompaignMail}
                bgColor={bgColor}
                fontFamily={fontFamily}
                setWordCount={setWordCount}
                wordCount={wordCount}
              />
            </div>
          ) : (
            <Slider {...settings}>
              {compaignMail?.map((item, i) => (
                <div key={i}>
                  <Emailtemplate
                    hideinput="d-none"
                    selected={selectedFile}
                    setSender={setSender}
                    sender={sender}
                    setSelected={setSelectedFile}
                    emailBody={item.template_body}
                    index={i}
                    setEmailBody={setCompaignMail}
                    wordCount={wordCount}
                    setWordCount={setWordCount}
                    bgColor={bgColor}
                    fontFamily={fontFamily}
                  />
                </div>
              ))}
            </Slider>
          )}

          <div className="start-left-bottom w-full v-center space-between">
            {/* <button
                  className="button-purple-transparent float-left px-3"
                  onClick={handleGenerateAndPreview}
                >
                  Generate & Preview Campaign
                </button> */}
            {genStatus ? (
              <div className="d-flex v-center h-center gap-3">
                <button
                  className="button-purple-transparent float-left px-3"
                  onClick={handleCancel}
                >
                  Cancel Campaign
                </button>
                <button
                  className="button-purple-transparent float-left px-3"
                  onClick={() => setIsModalOpen((prev) => !prev)}
                >
                  <IoEye size={24} /> Preview
                </button>
              </div>
            ) : (
              <button
                className="button-purple-transparent float-left px-3"
                onClick={handleGenerateAndPreview}
              >
                Generate Campaign
              </button>
            )}

            {compaignMail.length < 2 && (
              <button
                className={`button-purple-transparent ${!activePlan.subscription_name ||
                  compaignMail[0]?.template_body === "" ||
                  compaignMail[0]?.template_body === "<p><br></p>"
                  ? "btn-disable"
                  : ""
                  }`}
                onClick={() => {
                  navigate("/templates/preview-template?review=true");
                }}
              >
                Save as Template
              </button>
            )}
          </div>
        </div>

        {/* Sidebar */}
        <div className="start-right-section d-flex flex-column">
          <h5 className="medium-head inter-family text-color">Settings</h5>

          <div className="schedule-conatiner d-flex flex-column gap-2">
            <Tooltip title="CSV Preview">
              <button
                className={`preview-btn w-full mt-2`}
                onClick={showPreview}
              >
                Preview
              </button>
            </Tooltip>

            <>
              <p className="p-med segoe-family ">Schedule </p>

              <div className="settings-fields">
                <DatePicker
                  onChange={(date) => setScheduleDate(date)}
                  placeholder="Select a date"
                  variant="borderless"
                  className="w-full"
                  disabledDate={disabledDate}
                />
              </div>
            </>

            <>
              <p className="p-med segoe-family mt-2">Time</p>
              <div className="settings-fields">
                <TimePicker
                  placeholder="Select a time"
                  onChange={(time) =>
                    setScheduleTime(time ? time.format(format) : null)
                  }
                  format={format}
                  variant="borderless"
                  className="w-full"
                  use12Hours={true}
                  disabledTime={disabledTime}
                />

              </div>
            </>

            <>
              <p className="p-med segoe-family mt-2">Background Color</p>
              <div className="settings-fields">
                <ColorPicker
                  onChange={(color) => setBgColor(color.toHexString())}
                  value={bgColor}
                  showText
                />
              </div>
            </>

            <>
              <p className="p-med segoe-family mt-2">Font Family</p>

              <div className="settings-fields">
                <Select
                  variant="borderless"
                  className="w-full"
                  suffixIcon={<FaAngleDown className="down-icon" />}
                  placeholder="Select a font"
                  value={fontFamily}
                  onChange={(value) => setFontFamily(value)}
                  options={[
                    { value: "Segoe UI", label: "Segoe UI" },
                    { value: "Arial", label: "Arial" },
                    { value: "Times New Roman", label: "Times New Roman" },
                    { value: "Verdana", label: "Verdana" },
                  ]}
                />
              </div>
            </>
          </div>
        </div>
      </div>

      {isModalVisible && csvData.length !== 0 && (
        <Modal
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          className="previewexcel-modal"
          onCancel={() => setIsModalVisible(false)}
          width={1200}
        >
          <div className="excelmodal-content d-flex flex-column">
            <div className="filebanner-banner">{selectedFile}</div>

            <div className="excel-sheet">
              <Spreadsheet
                data={csvData}
                columnLabels={grid.columns}
                rowLabels={grid.rows}
                editable={false}
              />
            </div>

            <button className="edit-csv" onClick={handleFileEdit}>
              <MdModeEditOutline />
            </button>
          </div>
        </Modal>
      )}

      <Previewcampaignmodal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        preview={true}
        bgColor={bgColor}
        fontFamily={fontFamily}
        scheduleDate={scheduleDate}
        scheduleTime={scheduleTime}
        selectedFile={selectedFile}
        sender={sender}
        setSubject={setSubject}
        subject={subject}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        loader={loader}
        recordLength={recordLength}
      />

      {contextHolder}
    </>
  );
};

export default Startcampaign;
