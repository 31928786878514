import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useCompaign } from "../context/CompaignProvider";
import { usePlans } from "../context/PlansProvider";

const Editor = ({
  emailBody,
  setEmailBody,
  previewMode,
  index,
  bgColor,
  fontFamily,
  setWordCount,
}) => {
  const quillRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(null);
  const [options, setOptions] = useState(["{ }"]);
  const [optionsQuery, setOptionsQuery] = useState([]);
  const [disableBraces, setDisableBraces] = useState([]);
  const { columns } = useCompaign();
  const { activePlan } = usePlans();

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    [`customButton-${index}`],
    [`btn2-${index}`],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "clean"],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  const countBraceWrappedStrings = (text) => {
    const regex = /\{\{[^{}]*(?:\{[^{}]*\}[^{}]*)*\}\}/g;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleBraceCount = useCallback((content, idx) => {
    const count = countBraceWrappedStrings(content);
    setDisableBraces((current) => {
      const updated = [...current];
      const foundIndex = updated.findIndex((item) => item.id === idx);
      if (foundIndex !== -1) {
        updated[foundIndex] = { id: idx, braces: count >= 2 }; // Checks if there are two or more matches
      } else {
        updated.push({ id: idx, braces: count >= 2 });
      }
      return updated;
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(columns)) {
      setOptions([" ", ...columns]);
      setOptionsQuery(["", ...columns.map((column) => `{${column}}`)]);
    }
  }, [columns]);

  useEffect(() => {
    const quill = quillRef.current;

    if (quill) {
      const editor = quill.getEditor();
      editor.root.style.backgroundColor = bgColor;
      editor.root.style.setProperty("font-family", fontFamily, "important");
    }
  }, [fontFamily, bgColor]);

  const createCustomButton = useCallback(
    (buttonClass, buttonText, optionsArray, insertTextFormat) => {
      const quill = quillRef.current?.getEditor();
      if (!quill) return;

      const toolbarContainer = quill.getModule("toolbar").container;
      let buttonContainer = toolbarContainer.querySelector(
        `.ql-${buttonClass}-${index}`
      );
      if (!buttonContainer) {
        buttonContainer = document.createElement("span");
        buttonContainer.className = `ql-${buttonClass}`;
        toolbarContainer.appendChild(buttonContainer);
      }

      let button = buttonContainer?.querySelector("button");
      if (!button) {
        button = document.createElement("button");
        button.type = "button";
        button.innerHTML = buttonText;
        button.style.position = "relative";
        button.style.zIndex = "1";
        button.style.marginTop = "-4px";

        button.style.fontWeight = "600";
        button.style.whiteSpace = "nowrap";
        button.style.opacity = "0.8";
        button.onclick = (event) => {
          event.stopPropagation();
          setShowDropdown(buttonClass);
        };
        buttonContainer?.appendChild(button);
      } else {
        if (`.ql-btn2-${index}` === `.ql-${buttonClass}-${index}`) {
          const isDisabled =
            disableBraces.find((item) => item.id === index)?.braces || false;
          button.disabled = isDisabled;
        }
      }

      let dropdown = buttonContainer?.querySelector(".custom-dropdown");
      if (!dropdown) {
        dropdown = document.createElement("div");
        dropdown.className = "custom-dropdown";
        button.appendChild(dropdown);
      }

      dropdown.innerHTML = "";
      dropdown.style.position = "absolute";
      dropdown.style.display = "none";
      dropdown.style.flexDirection = "column";
      dropdown.style.backgroundColor = "white";
      dropdown.style.border = "1px solid #ccc";
      dropdown.style.padding = "5px";
      dropdown.style.boxShadow = "0px 8px 16px 0px rgba(0,0,0,0.2)";

      // Populate dropdown with new options
      if (optionsArray.length < 1) {
        const option = document.createElement("p");
        option.style.display = "block";
        option.style.width = "100%";
        option.style.fontSize = "12px";
        option.style.padding = "4px 10px";
        option.style.cursor = "auto";
        option.style.textAlign = "center";
        option.style.background = "none";
        option.style.opacity = "0.4";
        option.style.color = "black";
        option.textContent = "Select CSV First*";
        dropdown.appendChild(option);
      } else {
        optionsArray.forEach((placeholder) => {
          const option = document.createElement("button");
          option.style.display = "block";
          option.style.width = "fit-content";
          option.style.padding = "4px";
          option.style.textAlign = "left";
          option.style.background = "none";
          option.style.cursor = "pointer";
          option.type = "button";
          option.textContent =
            showDropdown === "btn2"
              ? `{{Type your query ${placeholder}}}`
              : `{${placeholder}}`;
          option.onclick = (event) => {
            event.stopPropagation();
            const selection = quill.getSelection();
            const formattedText = insertTextFormat(placeholder);

            if (selection && selection.length > 0) {
              quill.deleteText(selection.index, selection.length);
              quill.clipboard.dangerouslyPasteHTML(
                selection.index,
                `<span style="background: linear-gradient(to right, rgb(255, 126, 95), rgb(254, 180, 123));">${formattedText}</span>`
              );
              quill.setSelection(selection.index + formattedText.length);
            } else {
              const cursorPosition = selection
                ? selection.index
                : quill.getLength() - 1;
              quill.clipboard.dangerouslyPasteHTML(
                cursorPosition,
                `<span style="background: linear-gradient(to right, rgb(255, 126, 95), rgb(254, 180, 123));">${formattedText}</span>`
              );
              quill.setSelection(cursorPosition + formattedText.length);
            }
            setShowDropdown(null);
          };
          dropdown.appendChild(option);
        });
      }
    },
    [setShowDropdown, showDropdown, disableBraces, index]
  );

  useEffect(() => {
    createCustomButton(
      "customButton",
      "{ }",
      options,
      (placeholder) => `{${placeholder}}`
    );
    createCustomButton(
      "btn2",
      "{{ }}",
      optionsQuery,
      (placeholder) => `{{Type your Query ${placeholder}}}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, optionsQuery, showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(`.ql-customButton-${index} .custom-dropdown`) &&
        !event.target.closest(`.ql-customButton-${index} button`)
      ) {
        setShowDropdown(null);
      }
      if (
        !event.target.closest(`.ql-btn2-${index} .custom-dropdown`) &&
        !event.target.closest(`.ql-btn2-${index} button`)
      ) {
        setShowDropdown(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [index]);

  useEffect(() => {
    const dropdown = document.querySelector(
      `.ql-customButton-${index} .custom-dropdown`
    );
    const dropdown2 = document.querySelector(
      `.ql-btn2-${index} .custom-dropdown`
    );

    if (dropdown) {
      dropdown.style.display =
        showDropdown === "customButton" ? "flex" : "none";
    }

    if (dropdown2) {
      dropdown2.style.display = showDropdown === "btn2" ? "flex" : "none";
    }
  }, [showDropdown, index]);

  const countWords = (text) => {
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const content = quill.getText();
      setWordCount((prev) => {
        const prevCounts = [...prev];
        prevCounts[index] = countWords(content);
        return prevCounts;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailBody, index]);

  return (
    <>
      <div className={` ${activePlan?.subscription_name?.split(" ")[0] === "Premium" ? "" : "disable-image"}`}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          onChange={(content) => {
            if (previewMode) {
              setEmailBody((prev) =>
                prev.map((item) =>
                  item.email === emailBody.email
                    ? { ...item, response_text: content }
                    : item
                )
              );
            } else {
              setEmailBody((prev) =>
                prev.map((item, idx) =>
                  idx === index ? { ...item, template_body: content } : item
                )
              );
            }
            handleBraceCount(content, index);
          }}
          value={previewMode ? emailBody.response_text : emailBody}
          modules={modules}
          bounds={".app"}
          preserveWhitespace={true}
        />
      </div>
    </>
  );
};

export default Editor;
