import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCompaign } from "../context/CompaignProvider";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import removeTemplate from "../utils/removeTemplate";
import { IoCloseCircle } from "react-icons/io5";
import { Modal, notification } from "antd";
import { Tooltip } from "antd";
import { usePlans } from "../context/PlansProvider";
import { IoMdLock } from "react-icons/io";

const Templatecard = () => {
  const { templates, handleTemplates } = useCompaign();
  const { activePlan } = usePlans();
  const [selectCount, setSelectedCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const convertToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const toggleTemplateSelection = (templateName) => {
    const currentIndex = selectedTemplates.indexOf(templateName);
    const newSelectedTemplates = [...selectedTemplates];

    if (currentIndex === -1) {
      newSelectedTemplates.push(templateName);
    } else {
      newSelectedTemplates.splice(currentIndex, 1);
    }
    setSelectedCount(newSelectedTemplates.length);
    setSelectedTemplates(newSelectedTemplates);
  };

  const handleUseTemplates = () => {
    const rangeAllowed = activePlan?.ai_template_per_campaign
      ? activePlan.ai_template_per_campaign
      : 1;
    if (selectCount <= rangeAllowed) {
      setError("");
      const slug = selectedTemplates
        .map((name) => convertToSlug(name))
        .join(",");
      navigate(`/templates/preview-template?selected=${slug}`);
    } else {
      setError(`Maxmimum ${rangeAllowed} templates are allowed to be choosed!`);
    }
  };

  const handleDirectNavigation = (templateName) => {
    // const slug = convertToSlug(templateName);
    navigate(`/templates/preview-template?selected=${templateName}`);
  };

  const handleRemove = async () => {
    const response = await removeTemplate(templateName);
    if (response.success) {
      api.open({
        message: response.message,
        icon: <img src="../../assets/icons/tick-arrow.svg" alt="tick-arrow" />,
      });
      handleTemplates();
    } else {
      api.open({
        message: response.message,
        icon: <IoCloseCircle className="false-icon" />,
      });
    }
    setShowPopup(false);
    setTemplateName("");
  };

  const handleOk = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    handleTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="select-template">
        <Tooltip title="Templates selection for Compaign">
          <button
            className={`button-select ${selectCount < 1 && "btn-disable"}`}
            onClick={selectCount > 0 && handleUseTemplates}
          >
            <span>{selectCount}</span> Templates Selected
          </button>
        </Tooltip>
      </div>

      {error !== "" && (
        <p className="text-danger text-center text-error">{error}</p>
      )}

      <div className="d-flex v-center h-center">
        <div className="template-cards-wrapper">
          {templates.map((item, index) => (
            <div
              className={`template-card ${selectedTemplates.includes(item.template_name)
                ? "active-card"
                : ""
                }`}
              key={index}
            >
              <div className="temp-card-top">
                <div className="top-inner img-container h-center flex-column">
                  <img src={`data:image/png;base64,${item.contents}`} alt="" />
                </div>
              </div>

              <div className="temp-card-bottom h-center">
                <label>{item.template_name}</label>
              </div>

              <div className="action-ribbon">
                {item.temp_status === "active" ? (
                  <>
                    <Tooltip title={selectedTemplates.includes(item.template_name) ? "Remove from selection" : "Add to selection"}>
                      {selectedTemplates.includes(item.template_name) ? (
                        <FiMinusCircle
                          size={24}
                          className="item"
                          onClick={() => toggleTemplateSelection(item.template_name)}
                        />
                      ) : (
                        <FiPlusCircle
                          className="item"
                          onClick={() => toggleTemplateSelection(item.template_name)}
                        />
                      )}
                    </Tooltip>

                    <Tooltip title="Select template">
                      <FaRegCheckCircle
                        className="item"
                        size={24}
                        onClick={() => {
                          handleDirectNavigation(item.template_name);
                        }}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <div className={`premium blush`}>
                    <IoMdLock size={24} className="icon" />
                    <span>PREMIUM</span>
                  </div>
                )}

                <Tooltip title="Delete template">
                  <MdDeleteOutline
                    className="item"
                    size={24}
                    onClick={() => {
                      setShowPopup(true);
                      setTemplateName(item.template_name);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>

      {templates.length < 1 ? (
        <div className="p-4 text-center w-full">No Template Found!</div>
      ) : null}

      {contextHolder}

      {/* Remove Template Modal */}
      <>
        <Modal
          open={showPopup}
          onOk={handleOk}
          onCancel={() => setShowPopup(false)}
          className="logout-modal"
          centered={true}
        >
          <div className="modal-content">
            <div className="purple-header"></div>
            <div className="bottom-content v-center flex-column">
              <div className="img-modal-container">
                <img
                  src="../../assets/icons/remove_template.svg"
                  alt="logoutImg"
                  className="remove-template"
                />
              </div>
              <h4 className="elarge montserrat-family">
                Are you sure you want to delete this template?
              </h4>

              <div className="modal-buttons d-flex gap-16 mt-24">
                <button
                  className="text-darkpurple segoe-family"
                  onClick={() => setShowPopup(false)}
                >
                  No
                </button>

                <button onClick={handleRemove}>Yes</button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default Templatecard;
