const getQueryEmailTemplate = async (query) => {
    const tokens = sessionStorage.getItem("token")
    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/dynamic-template-with-token?query1=${query}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokens}`
                },
            }
        );
        const result = await response.json();
        if (response.ok) {
            return { success: true, text: result.text }
        } else {
            return { success: false, message: result.detail }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }

    }
}

export default getQueryEmailTemplate;