import React, { useCallback, useEffect, useState } from "react";
import { Modal, Pagination, Skeleton } from "antd";
// import { CSVLink } from "react-csv";
import Spreadsheet from "react-spreadsheet";
import getContactsNames from "../../utils/getContactsNames";
import fetchContactsByName from "../../utils/fetchContactsByName";
import getColumns from "../../utils/getColumns";
import deleteCSV from "../../utils/contacts/deleteCSV";
import { useCompaign } from "../../context/CompaignProvider";
import { notification } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import csvFileEdit from "../../utils/csvFileEdit";

const Listofcontacttable = ({
  isfilemodalOpen,
  listTable,
  setSelected,
  setisfilemodalOpen,
}) => {
  const [spreadsheetdata, setSpreadsheetdata] = useState([]);
  const { setColumns } = useCompaign();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filename, setFilename] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [grid, setGrid] = useState({ rows: [], columns: [] });
  const [selectedRow, setSelectedRow] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  // Pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = spreadsheetdata.slice(startIndex, endIndex);

  const promptDeleteFile = (filename) => {
    setShowDeleteConfirm(true);
    setFileToDelete(filename);
  };

  const handleDeleteFile = async () => {
    if (fileToDelete) {
      const response = await deleteCSV(fileToDelete);
      if (response.success) {
        api.open({
          message: response.message,
          icon: (
            <img src="../../../assets/icons/tick-arrow.svg" alt="tick-arrow" />
          ),
        });
        fetchContacts(); // Refresh the list after deletion
      } else {
        api.open({
          message: response.message,
          icon: <IoCloseCircle className="false-icon" />,
        });
      }
    }
    setShowDeleteConfirm(false); // Close the confirmation modal
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false); // Close the modal without deleting
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showModal = async (rowData) => {
    setIsModalVisible(true);
    setCsvData([]);
    setFilename(rowData.filename);

    const response = await fetchContactsByName(rowData.filename);
    if (response.success) {
      // Rows count
      const additionalRows = 4;
      const rowCount = Math.max(response.data.length, 1) + additionalRows;

      // Column count
      const maxKeys = Math.max(
        ...response.data.map((obj) => Object.keys(obj).length)
      );
      const additionalColumns = 1;
      const totalColumns = maxKeys + additionalColumns;

      setGrid((prev) => ({
        ...prev,
        rows: Array.from({ length: rowCount }, (_, i) => (i + 1).toString()),
        columns: Array.from({ length: totalColumns }, (_, i) =>
          numberToColumnLetter(i + 1)
        ),
      }));

      const keys = response.data.length ? Object.keys(response.data[0]) : [];
      const headers = keys.map((key) => ({ value: key, readOnly: true }));

      const gridData = Array.from({ length: rowCount }, (_, rowIndex) =>
        Array.from({ length: totalColumns }, (_, colIndex) => {
          const value =
            rowIndex === 0
              ? headers[colIndex]?.value || ""
              : response.data[rowIndex - 1]?.[keys[colIndex]] || "";
          return { value, readOnly: true };
        })
      );

      setCsvData(gridData);
    }
  };

  function numberToColumnLetter(number) {
    let letter = "";
    while (number > 0) {
      const remainder = (number - 1) % 26;
      letter = String.fromCharCode(65 + remainder) + letter;
      number = Math.floor((number - 1) / 26);
    }
    return letter;
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRowSelect = async (index, filename) => {
    setSelectedRow(index);
    setSelected(filename);
    setisfilemodalOpen(false);
    const result = await getColumns(filename);
    if (result.success) {
      setColumns(result.data);
    }
  };

  const renderSkeletonTable = () => {
    const rows = 3;
    const columns = 4;

    return (
      <table className="m-auto">
        <thead>
          <tr className="send-email-thead">
            <th>
              <div className="email-check-div v-center gap-4 px-3">
                List Name
              </div>
            </th>
            <th>Total Contact</th>
            {!listTable && (
              <>
                <th>Last Used</th>
                <th>Action</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex} className="send-email-tbody">
              {Array.from({ length: columns }).map((_, colIndex) =>
                listTable ? (
                  colIndex > 1 ? (
                    <td key={colIndex}>
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: "100%" }}
                      />
                    </td>
                  ) : null
                ) : (
                  <td key={colIndex}>
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: "100%" }}
                    />
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedTimeUpperCase = formattedTime.replace(/am|pm/i, match => match.toUpperCase());

    return `${formattedDate} at ${formattedTimeUpperCase}`;
  };

  const fetchContacts = useCallback(async () => {
    if (!isfilemodalOpen) {
      const response = await getContactsNames();
      if (response.success) {
        setSpreadsheetdata(response.data.reverse()); // Reverse the data once here
      }
      setLoading(false);
    }
  }, [isfilemodalOpen]);

  const handleFileEdit = async () => {
    const response = await csvFileEdit(filename);

    if (response.success) {
      window.open(response.link, '_blank');
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [isfilemodalOpen, fetchContacts]);

  return (
    <>
      <div className="list-tabl-div">
        {loading ? (
          renderSkeletonTable()
        ) : (
          <>
            <table className="m-auto">
              <thead>
                <tr className="send-email-thead ">
                  <th>
                    <div className="email-check-div v-center gap-4 px-3">
                      List Name
                    </div>
                  </th>
                  <th>Total Contact</th>
                  {!listTable && (
                    <>
                      <th>Last Used</th>
                      <th>Action</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {visibleData
                  ?.map((data, index) => (
                    <tr
                      key={index}
                      className="send-email-tbody"
                      onClick={() =>
                        listTable ? handleRowSelect(index, data.filename) : {}
                      }
                      style={{
                        background:
                          listTable && selectedRow === index
                            ? "#f0f0f0"
                            : "transparent",
                      }}
                    >
                      <td>
                        <div className="email-check-div v-center gap-4 px-3">
                          {data.filename.length > 30 ? `${data.filename.slice(0, 30)}...` : data.filename}
                        </div>
                      </td>
                      <td>{data.record_count}</td>
                      {!listTable && (
                        <>
                          <td>
                            {data.lastused_datetime !== null
                              ? formatDate(data.lastused_datetime)
                              : null}
                          </td>
                          <td className="d-flex align-center gap-2">
                            <button onClick={() => showModal(data)}>Details</button>
                            <button
                              className="danger"
                              onClick={() => promptDeleteFile(data.filename)}
                            >
                              Delete
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            {spreadsheetdata.length < 1 && (
              <div className="text-center text-black montserrat-family w-full p-4">
                No CSV to show here
              </div>
            )}
          </>
        )}
      </div>

      <div className="contacts-bottom-div d-flex space-between align-items-center gap-2">
        <label className="small">{`Showing ${spreadsheetdata.length === 0 ? startIndex : startIndex + 1} to ${endIndex > spreadsheetdata.length ? spreadsheetdata.length : endIndex
          } of ${spreadsheetdata.length} results`}</label>
        <Pagination
          defaultCurrent={1}
          total={spreadsheetdata.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      </div>

      {/* Logout Modal */}
      <>
        <Modal
          open={showDeleteConfirm}
          onOk={handleDeleteFile}
          onCancel={handleCancelDelete}
          className="logout-modal"
          centered={true}
        >
          <div className="modal-content">
            <div className="purple-header"></div>
            <div className="bottom-content v-center flex-column">
              <div className="img-modal-container">
                <img src="../../assets/icons/profileImg.svg" alt="logoutImg" />
              </div>

              <h4 className="elarge montserrat-family">
                Are you sure you want to delete {fileToDelete}?
              </h4>

              <div className="modal-buttons d-flex gap-16 mt-24">
                <button
                  className="text-darkpurple segoe-family"
                  onClick={handleCancelDelete}
                >
                  No
                </button>

                <button onClick={handleDeleteFile}>Yes</button>
              </div>
            </div>
          </div>
        </Modal>
      </>

      {isModalVisible && csvData.length !== 0 && (
        <Modal
          // title="CSV File Details"
          open={isModalVisible}
          onOk={handleOk}
          className="previewexcel-modal"
          onCancel={handleCancel}
          width={1200}
        >
          <div className="excelmodal-content d-flex flex-column">
            <div className="filebanner-banner">{filename}</div>

            <div className="excel-sheet">
              <Spreadsheet
                data={csvData}
                columnLabels={grid.columns}
                rowLabels={grid.rows}
                editable={false}
              />
            </div>

            <button className="edit-csv" onClick={handleFileEdit}>
              <MdModeEditOutline />
            </button>
          </div>
        </Modal>
      )}

      {contextHolder}
    </>
  );
};

export default Listofcontacttable;
