import React, { useEffect, useState, useRef } from "react";
import getQueryEmailTemplate from "../utils/getQueryEmailTemplate";
import getProfile from "../utils/getProfile";
import { Modal, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { FaPaperPlane } from "react-icons/fa6";
import { usePlans } from "../context/PlansProvider";
import { MdLock } from "react-icons/md";

const Variationcard = ({
  id,
  selected,
  setSelected,
  variationCards,
  updateVariationCard,
  aiPrompts,
  setAIPrompts,
}) => {
  const [generating, setGenerating] = useState("Generate");
  const { activePlan } = usePlans();
  const [isAllowed, setIsAllowed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const responseRef = useRef(null);

  const card = variationCards.find((card) => card.id === id);
  const fieldPrompt = card ? card.prompt : "";
  const fieldResponse = card ? card.response : "";

  const handleInputChange = (event) => {
    updateVariationCard(id, event.target.value, "", "");
  };

  const handleGenerate = async () => {
    if (isAllowed && fieldPrompt !== "") {
      setGenerating("Generating");
      const response = await getQueryEmailTemplate(fieldPrompt);
      if (response.success) {
        const newPromptResponse = {
          id,
          prompt: fieldPrompt,
          response: response.text,
        };
        updateVariationCard(id, fieldPrompt, "", response.text);
        setAIPrompts(
          variationCards.map((p) => (p.id === id ? newPromptResponse : p))
        );
        setGenerating("Regenerate");
      } else {
        console.error(response.message);
        updateVariationCard(
          id,
          fieldPrompt,
          "Failed to generate response!",
          fieldResponse
        );
        setGenerating("Regenerate");
      }
    } else {
      updateVariationCard(
        id,
        fieldPrompt,
        "Campaign text variation is required!",
        fieldResponse
      );
      setShowPopup(true);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await getProfile();
      if (response.success) {
        if (
          response.data.company_name === "" ||
          response.data.product_or_service === "" ||
          response.data.industry === "" ||
          response.data.company_website_link === ""
        ) {
          setIsAllowed(false);
        } else {
          setIsAllowed(true);
        }
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [fieldPrompt]);

  useEffect(() => {
    if (responseRef.current) {
      responseRef.current.style.height = "auto";
      responseRef.current.style.height = `${responseRef.current.scrollHeight}px`;
    }
  }, [fieldResponse]);

  return (
    <>
      <div
        className="create-template-card"
        onClick={() => setSelected(id)}
      >
        <div className={`variation-card gap-8`}>
          <div className="variation-card-relative">
            <textarea
              ref={inputRef}
              rows="3"
              value={fieldPrompt}
              onChange={handleInputChange}
              placeholder="Type your prompt here..."
              className={`compaign-template-field ${
                selected ? "selected" : ""
              }`}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            ></textarea>

            <div
              className={`element-loading absolute ${
                generating === "Generating" ? "visible" : "invisible"
              }`}
            >
            </div>

            <div
              className={`element-loading absolute ${
                !activePlan.subscription_name ? "visible" : "invisible"
              }`}
            >
              <MdLock className="lock" />
            </div>
          </div>

          <div className="btn-generate-between">
            <Tooltip title={generating}>
              <button
                onClick={handleGenerate}
                disabled={generating === "Generating"}
                className={`${
                  !activePlan.subscription_name || generating === "Generating" ? "btn-disable" : ""
                }`}
              >
                <FaPaperPlane />
              </button>
            </Tooltip>
          </div>

          <div className="variation-card-relative">
            <textarea
              ref={responseRef}
              rows="3"
              value={fieldResponse}
              onChange={(e) =>
                updateVariationCard(id, fieldPrompt, "", e.target.value)
              }
              placeholder="Response..."
              className={`compaign-template-field ${
                selected ? "selected" : ""
              }`}
              style={{ height: "auto" }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            ></textarea>

            <div
              className={`element-loading absolute ${
                generating === "Generating" ? "visible" : "invisible"
              }`}
            >
              <div className="spinner" />
            </div>

            <div
              className={`element-loading absolute ${
                !activePlan.subscription_name ? "visible" : "invisible"
              }`}
            >
              <MdLock className="lock" />
            </div>
          </div>
        </div>
      </div>

      {/* Logout Modal */}
      <>
        <Modal
          open={!isAllowed && showPopup}
          onOk={() => setShowPopup(false)}
          onCancel={() => setShowPopup(false)}
          className="logout-modal"
          centered={true}
        >
          <div className="modal-content">
            <div className="purple-header"></div>
            <div className="bottom-content v-center flex-column">
              <div className="img-modal-container">
                <img src="../../assets/icons/profileImg.svg" alt="logoutImg" />
              </div>
              <h4 className="elarge montserrat-family">
                Need profile information!
                <br /> Want to fill?
              </h4>

              <div className="modal-buttons d-flex gap-16 mt-24">
                <button
                  className="text-darkpurple segoe-family"
                  onClick={() => setShowPopup(false)}
                >
                  No
                </button>

                <button
                  onClick={() => {
                    setShowPopup(false);
                    navigate("/profile");
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default Variationcard;
