import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./style/main.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CompaignProvider } from "./context/CompaignProvider";
import { PlansProvider } from "./context/PlansProvider";
import { NotificationProvider } from "./context/NotificationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const disableConsoleLogging = () => {
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
//   console.info = () => {};
//   console.debug = () => {};
// };

// disableConsoleLogging();

root.render(
  <CompaignProvider>
    <NotificationProvider>
      <PlansProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </PlansProvider>
    </NotificationProvider>
  </CompaignProvider>
);
