const readNotification = async (id) => {
    const tokens = sessionStorage.getItem("token");

    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/change-notification-status?id=${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokens}`,
                },
            }
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, notifications: result };
        } else {
            return { success: false, message: result.message };
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` };
    }
};

export default readNotification;
