import React from "react";
import { useNavigate } from "react-router-dom";
import Templatecard from "../shared/templatecard";
import { Modal } from "antd";

const Templatemodal = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="tempalte-modal"
        centered={true}
        width="70vw"
      >
        <div className="modal-content">
          <button
            className="v-center h-center gap-8 text-black generatebtn"
            onClick={() => {
              navigate("/dashboard/createcampaign");
            }}
          >
            Generate with AI
            <img src="../../../assets/icons/glitter.svg" alt="" />
          </button>
          <h5 className="large text-center text-beige">Or</h5>
          <h5 className="large text-center text-light-black">Use a template</h5>
          <Templatecard />
        </div>
      </Modal>
    </>
  );
};

export default Templatemodal;
