import React from "react";
import Platform from "./platform/platform";
// import Info from "./info/info";
import WorkingSteps from "./WorkingSteps/WorkingSteps";
import './howitworks.scss';

function HowitWorks() {

  return (
    <div className="how-it-works">
      <Platform />
      {/* <Info /> */}
      <WorkingSteps />
    </div>
  );
}

export default HowitWorks;
