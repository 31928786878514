import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { usePlans } from "../../context/PlansProvider";
import { MdLock } from "react-icons/md";
import CancelPlan from "../../modals/CancelPlan";

const Settingssubscription = () => {
  const [toggleCancel, setToggleCancel] = useState(false);
  const { activePlan } = usePlans();

  const features = [
    `Personalization campaigns — ${activePlan.ai_written_section_allowed} AI Written section`,
    `${activePlan.plan_template_count} AI templates/ month (${activePlan.ai_written_section_allowed} per campaign)`,
    `${activePlan.words_per_email} words per email`,
    `Additional templates — ${activePlan.additional_template_count || 0}`,
  ];

  return (
    <>
      <div className="setting-subs-main h-center">
        <div className="wrapper-relative">
          {!activePlan.subscription_name && (
            <div className="locked-feature">
              <MdLock className="lock" />
            </div>
          )}

          <div className="pro-service-card d-flex flex-column text-white">
            <p className="text-center">
              <span className="text-sm">You subscribed to our</span>
              <h6>{activePlan.subscription_name}</h6>
              <span className="text-sm">Services</span>
            </p>

            <div className="current-credits-card">
              <img
                src="../../assets/images/dashboardimages/procard-bg.png"
                alt=""
                className="w-100 h-100"
              />
              <div className="currentcard-content v-center flex-column">
                <label className="segoe-family text-white">
                  Your Current Credits
                </label>
                <span className="segoe-family text-white v-center flex-column mt-3">
                  {activePlan.email_allowed} <span>Month</span>
                  {/* {activePlan.duration} */}
                </span>
              </div>
            </div>

            <div className="other-details-div d-flex flex-column">
              <p className="montserrat-family">Other Details</p>
              {features.map((item, i) => (
                <span key={i} className={`${i === 0 ? "" : "mt-4"}`}>
                  {item}
                </span>
              ))}
            </div>

            <div className="m-auto">
              <button
                className="text-white segoe-family text-center"
                onClick={() => setToggleCancel(true)}
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </div>

      <CancelPlan toggleCancel={toggleCancel} setToggleCancel={setToggleCancel} />
    </>
  );
};

export default Settingssubscription;
