import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
// import { Select } from "antd";
import DeleteAccountModal from "../modals/deleteAccountModal";
import { notification } from "antd";
import "react-toastify/dist/ReactToastify.css";
import setProfileInfo from "../utils/setProfileInfo";
import { FaCircleUser } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { useCompaign } from "../context/CompaignProvider";

const ProfileComponent = () => {
  const { profile } = useCompaign();
  const [showoldPassword, setShowoldPassword] = useState(false);
  const [profileForm, setProfileForm] = useState({
    avatar: null,
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    job_title: "",
    company_name: "",
    product_or_service: "",
    industry: "",
    company_website_link: "",
    additional_information: "",
  });
  const [shownewPassword, setShownewPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file !== undefined) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileForm((prev) => ({
          ...prev,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setProfileForm((prev) => ({
        ...prev,
        avatar: "",
      }));
    }
  };

  const toggleoldPasswordVisibility = () => {
    setShowoldPassword(!showoldPassword);
  };

  const togglenewPasswordVisibility = () => {
    setShownewPassword(!shownewPassword);
  };

  const toggleconfirmPasswordVisibility = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };

  const handleOnProfileChange = (field, value) => {
    setProfileForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    if (profileForm.username === "") {
      api.open({
        message: "Username is required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (profileForm.firstname === "") {
      api.open({
        message: "First name is required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (profileForm.lastname === "") {
      api.open({
        message: "Last name is required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else if (profileForm.avatar === "") {
      api.open({
        message: "Avatar is required!",
        icon: <IoCloseCircle className="false-icon" />,
      });
    } else {
      const response = await setProfileInfo(profileForm);
      if (response.success) {
        api.open({
          message: response.message,
          icon: <img src="../../assets/icons/tick-arrow.svg" alt="tick-arrow" />,
        });
      } else {
        api.open({
          message: response.message,
          icon: <IoCloseCircle className="false-icon" />,
        });
      }
    }
  };

  useEffect(() => {
    setProfileForm(profile);
  }, [profile]);

  return (
    <>
      {/* Top section */}
      <div className="profile-main-container d-flex gap-3">
        {/* Profile section */}
        <div className="profile-left-container d-flex flex-column">
          <div className="profile-dp-container profile-bg-radius">
            <div className="purple-container"></div>

            <div className="profile-name-container v-center h-center flex-column">
              <div className="profile-inner">
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <label htmlFor="fileInput">
                  {profileForm.avatar !== "" ? (
                    <div className="img-wrapper">
                      <img
                        src={profileForm.avatar}
                        alt="userdp"
                        className="user-dp-img"
                      />
                    </div>
                  ) : (
                    <FaCircleUser className="user-dp-icon" />
                  )}

                  <img
                    src="../assets/icons/edit.svg"
                    alt=""
                    className="cursor-pointer profile-editIcon"
                  />
                </label>
              </div>
              <h6 className="text-darkpurple mt-3 plus-jakarta-font">
                {profileForm.firstname} {" "} {profileForm.lastname !== "undefined" ? profileForm.lastname : ""}
              </h6>
              <span className="text-beige fw-medium mt-1 plus-jakarta-font">
                Account type:{" "}
                <span className="text-darkpurple fw-bold">Administrator</span>
              </span>
            </div>
          </div>

          <div className="account-setting-container profile-bg-radius">
            <label className="text-darkpurple elarge plus-jakarta-font">
              Account Settings
            </label>

            <form
              onSubmit={(e) => e.preventDefault()}
              className="accountsetting-inner"
            >
              <div className="username-email-div d-flex space-between gap-4">
                <span className=" d-flex flex-column gap-8 w-100">
                  <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                    Username
                  </p>
                  <input
                    type="text"
                    placeholder="@parkson.adela"
                    value={profileForm.username}
                    onChange={(e) =>
                      handleOnProfileChange("username", e.target.value)
                    }
                    className="profile-input"
                  />
                </span>

                <span className=" d-flex flex-column gap-8 w-100">
                  <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                    Email Address
                  </p>
                  <input
                    type="email"
                    placeholder="hello@horizon-ui.com"
                    disabled
                    className="profile-input"
                    value={profileForm.email}
                    onChange={(e) =>
                      handleOnProfileChange("email", e.target.value)
                    }
                  />
                </span>
              </div>

              <div className="name-div d-flex space-between gap-4">
                <span className=" d-flex flex-column gap-8 w-100">
                  <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                    First Name
                  </p>
                  <input
                    type="text"
                    placeholder="Adela"
                    className="profile-input"
                    value={profileForm.firstname}
                    onChange={(e) =>
                      handleOnProfileChange("firstname", e.target.value)
                    }
                  />
                </span>

                <span className=" d-flex flex-column gap-8 w-100">
                  <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                    Last Name
                  </p>
                  <input
                    type="text"
                    placeholder="Parkson"
                    className="profile-input"
                    value={profileForm.lastname}
                    onChange={(e) =>
                      handleOnProfileChange("lastname", e.target.value)
                    }
                  />
                </span>
              </div>

              <div className="jobtitle-div d-flex space-between gap-4">
                <span className=" d-flex flex-column gap-8 w-100">
                  <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                    Job Title
                  </p>
                  <input
                    type="text"
                    placeholder="Parkson"
                    className="profile-input"
                    value={profileForm.job_title}
                    onChange={(e) =>
                      handleOnProfileChange("job_title", e.target.value)
                    }
                  />
                  {/* <Select
                    defaultValue="Web Developer"
                    options={[
                      { value: "Web Developer", label: "Web Developer" },
                      { value: "App Developer", label: "App Developer" },
                    ]}
                  /> */}
                </span>
              </div>
            </form>
          </div>
        </div>

        <div className="profile-right-container d-flex flex-column">
          <div className="company-info-container profile-bg-radius">
            <label className="text-darkpurple elarge plus-jakarta-font">
              Company Information
            </label>

            <p className="plus-jakarta-font text-beige mt-2">
              Tell us about you
            </p>

            <form className="compinfo-inner d-flex flex-column">
              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Company Name
                </p>
                <input
                  name="companyName"
                  type="text"
                  // placeholder="Company Name"
                  className="profile-input"
                  value={profileForm.company_name}
                  onChange={(e) =>
                    handleOnProfileChange("company_name", e.target.value)
                  }
                />
              </span>

              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Product or Service
                </p>
                <input
                  type="text"
                  // placeholder="Product or Service"
                  className="profile-input"
                  value={profileForm.product_or_service}
                  onChange={(e) =>
                    handleOnProfileChange("product_or_service", e.target.value)
                  }
                />
              </span>

              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Industry
                </p>
                <input
                  type="text"
                  // placeholder="Industry "
                  className="profile-input"
                  value={profileForm.industry}
                  onChange={(e) =>
                    handleOnProfileChange("industry", e.target.value)
                  }
                />
              </span>

              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Company Website Link
                </p>
                <input
                  name="company_website_link"
                  type="url"
                  className="profile-input"
                  value={profileForm.company_website_link}
                  onChange={(e) =>
                    handleOnProfileChange(
                      "company_website_link",
                      e.target.value
                    )
                  }
                />
              </span>

              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Additional Information
                </p>
                <input
                  type="text"
                  // placeholder="Additional Information"
                  className="profile-input"
                  value={profileForm.additional_information}
                  onChange={(e) =>
                    handleOnProfileChange(
                      "additional_information",
                      e.target.value
                    )
                  }
                />
              </span>
            </form>
          </div>

          {/* Hidden Change password */}
          <div className="d-none change-pswd-container profile-bg-radius">
            <label className="text-darkpurple elarge plus-jakarta-font">
              Change password
            </label>
            <div className="changepswd-inner d-flex flex-column">
              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  Old Password
                </p>
                <div className="input-profile-password">
                  <input
                    type={showoldPassword ? "text" : "password"}
                    placeholder="Old Password"
                    className="profile-input"
                  />
                  <Icon
                    icon={
                      showoldPassword ? "fa-solid:eye" : "fa6-solid:eye-slash"
                    }
                    className="Peye-icon"
                    onClick={toggleoldPasswordVisibility}
                    color=" rgba(0, 0, 0, 0.4)"
                    width={18}
                    height={18}
                  />
                </div>
              </span>
              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  New Password
                </p>
                <div className="input-profile-password">
                  <input
                    type={shownewPassword ? "text" : "password"}
                    placeholder="New Password"
                    className="profile-input"
                  />
                  <Icon
                    icon={
                      shownewPassword ? "fa-solid:eye" : "fa6-solid:eye-slash"
                    }
                    className="Peye-icon"
                    onClick={togglenewPasswordVisibility}
                    color=" rgba(0, 0, 0, 0.4)"
                    width={18}
                    height={18}
                  />
                </div>
              </span>
              <span className=" d-flex flex-column gap-8 w-100">
                <p className="body-med text-darkpurple plus-jakarta-font mx-2">
                  New Password Confirmation
                </p>
                <div className="input-profile-password">
                  <input
                    type={showconfirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="profile-input"
                  />
                  <Icon
                    icon={
                      showconfirmPassword
                        ? "fa-solid:eye"
                        : "fa6-solid:eye-slash"
                    }
                    className="Peye-icon"
                    onClick={toggleconfirmPasswordVisibility}
                    color=" rgba(0, 0, 0, 0.4)"
                    width={18}
                    height={18}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Delete account */}
      <div className="profile-bottom-container profile-bg-radius d-flex space-between">
        <button className="fw-semibold plus-jakarta-font" onClick={showModal}>
          Delete Account
        </button>

        <button className="button-purple-linear" onClick={handleSubmit}>
          Save Changes
        </button>
      </div>
      
      {contextHolder}

      <DeleteAccountModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default ProfileComponent;
