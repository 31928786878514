import React from "react";
import Templatecard from "../../shared/templatecard";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

const Templatemain = () => {
  const navigate = useNavigate();

  return (
    <div className="template-conatiner h-center">
      <div className="template-main-container d-flex flex-column">
        <Tooltip title="AI template generation">
          <button
            className="v-center h-center gap-8 text-black generate-btn"
            onClick={() => {
              navigate("/dashboard/createcampaign");
            }}
          >
            Generate with AI
            <img src="../../../assets/icons/glitter.svg" alt="" />
          </button>
        </Tooltip>

        <h5 className="large text-center text-beige">Or</h5>
        <h5 className="large text-center text-light-black">
          Select a template
        </h5>
        <Templatecard />
      </div>
    </div>
  );
};

export default Templatemain;
