import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import deleteAccount from "../utils/deleteAccount";
import { useCompaign } from "../context/CompaignProvider";

const DeleteAccountModal = ({ isModalOpen, setIsModalOpen }) => {
  const { setCompaignMail, setSubject, setSelectedFile, setColumns } = useCompaign();
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    const keysToRemove = ["token", "first_name", "last_name", "email", "avatar", "template", "subject", "textColor", "bgColor", "linkColor"];
    keysToRemove.forEach(key => sessionStorage.removeItem(key));
    setCompaignMail([""]);
    setSubject('');
    setSelectedFile('');
    setColumns([]);
    handleCancel();
    navigate("/");
  };

  const handleDelete = async () => {
    setDisable(true)
    const userid = sessionStorage.getItem("email");
    const response = await deleteAccount(userid);
    if (response.success) {
      setDisable(true)
      setIsModalOpen(false);
      handleLogout()
    }
  }
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="credit-modal"
        centered={true}
        width="28%"
      >
        <div className="modal-content">
          <div className="purple-header"></div>
          <div className="bottom-content v-center flex-column">
            <img
              src="../../assets/images/dashboardimages/delAccountImg.svg"
              alt="credits"
              className="w-auto h-auto"
            />
            <h4 className="elarge montserrat-family text-center">
              Are you sure you want to delete your account
            </h4>

            <div className="modal-buttons d-flex gap-16 mt-24">
              <button
                className="text-darkpurple segoe-family del-acc-btn d-flex v-center h-center"
                onClick={handleDelete}
                style={{
                  opacity: disable ? 0.6 : 1,
                  cursor: disable ? "not-allowed" : "pointer",
                }}
                disabled={disable}
              >
                {disable ? (
                  <div className="d-flex h-center v-center gap-2">
                    <div className="send-loading" />
                    <span>Deleting...</span>
                  </div>
                ) : (
                  "Delete Account"
                )}
              </button>

              <button onClick={handleCancel} className="del-acc-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
