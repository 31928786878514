export const gdriveAccessCheck = async () => {
    const tokens = sessionStorage.getItem("token")

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/check-user-oauth`,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${tokens}`
                },
            }
        );

        const result = await response.json();
        if (response.ok) {
            return { hasAccess: result }
        } else {
            return { hasAccess: result }
        }
    } catch (e) {
        return { hasAccess: false }

    }
}