const uploadGoogleSheet = async (url, asgname) => {
    const tokens = sessionStorage.getItem("token")

    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/upload-google-sheet?filename=${asgname}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokens}`
                },
                body: JSON.stringify({ url: url })
            }
        );

        const result = await response.json();
        if (response.ok) {
            return { success: true, result: "Uploaded successfully!" }
        } else {
            return { success: false, message: result.detail }
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` }
    }
}

export default uploadGoogleSheet
