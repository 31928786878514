const StripePayment = async (data) => {
    const tokens = sessionStorage.getItem("token");
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/create-checkout-session`,
        // `http://localhost:8000/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
  
      if (response.ok) {
        return { success: true, session: result.sessionId };
      } else {
        return { success: false, message: result.detail };
      }
    } catch (e) {
      return { success: false, message: `Something went wrong: ${e}` };
    }
  };
  
  export default StripePayment;
  