const getCampaignSends = async () => {
  const tokens = sessionStorage.getItem("token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/total-and-lastday-send`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
      }
    );
    const result = await response.json();
    if (response.ok) {
      return {
        success: true,
        today_sends: result.total_email,
        last_day_sends: result["Last Day Count"],
      };
    } else {
      return { success: false, message: result.message };
    }
  } catch (e) {
    return { success: false, message: `Something went wrong: ${e}` };
  }
};

export default getCampaignSends;
