import React from "react";
import SubscriptionComponent from "../../components/subscriptioncomp";
const Subscription = () => {
  return (
    <>
      <div className="subs-main d-flex flex-column">
        <h4 className="elarge">Subscription</h4>
        <SubscriptionComponent />
      </div>
    </>
  );
};

export default Subscription;
