import { loadStripe } from '@stripe/stripe-js';
import { Checkbox, Modal, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router';
import StripePayment from '../utils/payment/StripePayment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ApproveBuySubscription = ({ purchaseType, setPurchaseType, annual }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const [api] = notification.useNotification();

    const handleChecked = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleCheckout = async () => {
        const stripe = await stripePromise;
        setDisable(true);
        const data = {
            plan_name: purchaseType === 'Free Trial' ? 'Free Trial' : `${purchaseType} ${annual ? "Yearly" : "Monthly"}`,
            // price: plan.price,
            // duration: annual ? "year" : "month",
            // email: sessionStorage.getItem("email"),
        };

        const response = await StripePayment(data);
        if (response.success) {
            const result = await stripe.redirectToCheckout({
                sessionId: response.session,
            });

            if (result.error) {
                console.error(result.error.message);
            } else {
                navigate("/settings?tab=subscription");
            }
        } else {
            let errorMessage = response.message;

            if (errorMessage.includes("already has an active subscription")) {
                errorMessage = "You already have an active subscription.";
            }
            api.open({
                message: errorMessage,
                icon: <IoCloseCircle className="false-icon" />,
            });
        }
    };

    useEffect(() => {
        if (purchaseType !== '') {
            setIsChecked(false);
        }
    }, [purchaseType]);
    

    return (
        <Modal
            open={purchaseType !== ''}
            onOk={() => setPurchaseType('')}
            onCancel={() => setPurchaseType('')}
            className="credit-modal"
            centered={true}
        >
            <div className="modal-content">
                <div className="purple-header"></div>
                <div className="bottom-content v-center flex-column">
                    <img
                        src="../../assets/images/dashboardimages/creditImg.svg"
                        alt="credits"
                        className="w-auto h-auto"
                    />

                    <div className='approve-modal'>
                    <Checkbox size='small' onChange={handleChecked} checked={isChecked} />
                    <p>Everything is confidential and we are saving any payment information with a check box agree and continue that user is trusting this platform and sharing the details on its behalf. So, approve this step to continue!</p>
                    </div>

                    <div className={`modal-buttons d-flex gap-16 mt-24`}>
                        <button
                            className="text-darkpurple segoe-family"
                            onClick={() => {
                                setPurchaseType('');
                                setIsChecked(false)
                            }}
                        >
                            Disagree
                        </button>
                        <button className={`d-flex h-center v-center gap-2 ${disable || !isChecked ? 'btn-disable' : ''}`} onClick={handleCheckout}>
                            {disable ? <span className="btn-spinner"></span> : "Agree"}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ApproveBuySubscription