const deleteAccount = async (userid) => {
    const tokens = sessionStorage.getItem("token");

    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/delete-user-account`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokens}`,
                },
                body: JSON.stringify({
                    userid: userid,
                })
            }
        );
        const result = await response.json();
        if (response.ok) {
            return { success: true, message: result.message };
        } else {
            return { success: false, message: result.message };
        }
    } catch (e) {
        return { success: false, message: `Something went wrong: ${e}` };
    }
};

export default deleteAccount;
