export const GlobalDebug = (function () {
  // Store a reference to the original console object
  var savedConsole = console;

  /**
   * GlobalDebug function to enable or disable console logging
   * @param {boolean} debugOn - If false, disables console logging; if true, restores console logging.
   * @param {boolean} suppressAll - If true, suppresses all console logs including info, warn, and error.
   */
  return function (debugOn, suppressAll = false) {
    if (debugOn === false) {
      // Override console methods to no-op functions
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};

      // If suppressAll is true, disable all types of console logging
      if (suppressAll) {
        console.debug = function () {};
        console.trace = function () {};
        console.dir = function () {};
        console.dirxml = function () {};
        console.group = function () {};
        console.groupEnd = function () {};
        console.time = function () {};
        console.timeEnd = function () {};
        console.assert = function () {};
        console.count = function () {};
      }
    } else {
      // eslint-disable-next-line
      console = savedConsole;
    }
  };
})();
